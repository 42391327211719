import { get,post,put,del } from "../Fetch"
import { useSelector } from "react-redux";
const readAll=async (staff_id,limit,page,query)=>{
    const req=await get("admin/contact/"+staff_id+"?limit="+limit+"&page="+page+"&q="+query);
    return req
}
const readOne=async (staff_id,id)=>{
    const req=await get("admin/contact/"+staff_id+"/"+id);
    return req
}

const addData=async (staff_id,formRef)=>{
    const fr=new FormData();
    fr.append("icon",formRef.icon.current.value);
    fr.append("link",formRef.link.current.value);
    const req=await post("admin/contact/"+staff_id,fr);
    return req
}
const update=async (formRef)=>{
    const fr=new FormData();
    fr.append("icon",formRef.icon.current.value);
    fr.append("link",formRef.link.current.value);
    const req=await put("admin/contact/"+formRef.id.current,fr);
    return req
}
const deleteData=async (id)=>{
    const req=await del("admin/contact/"+id);
    return req
}

export {readAll,addData,update,readOne,deleteData}