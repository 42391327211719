import { get } from "./Fetch"

const checkNotification=async ()=>{
    const req=await get("check-notification");
    return req
}
const readNotification=async ()=>{
    const req=await get("read-notification");
    return req
}
const readAllNotification=async ()=>{
    const req=await get("read-all-notification");
    return req
}
export {checkNotification,readAllNotification,readNotification}