import {Row,Col, Table} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpDown,faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons"
import TextInput from '../../Components/Shared/TextInput';
import Select from '../../Components/Shared/Select';
import { useEffect, useRef, useState } from 'react';
const DataTables=({tableBody,data,tableHead,currentPage,maxPage,onLimitChange,onSearch,limitRef,searchRef,limitList,onPageChange})=> {
    const [currentPageState,setCurrentPage]=useState(currentPage);
    const [maxPageState,setMaxPage]=useState(maxPage);
   const [pageElement,setPageElement]=useState([]);
   const setPage=()=>{        
    let max=currentPageState+2;
    let min=currentPageState-2;
    
    if(min<=0){
        min=1;
    }
    if(currentPageState<=3){
        max=5;
    }
    if(max>maxPageState){
        max=maxPageState;
    }
    console.log(max);
    setPageElement((n)=>{
        let newElement=[];
        for(let i=min;i<=max;i++){
            newElement.push(i);
        }
        return newElement;
    })
}
   useEffect(()=>{
    setPage()
   },[currentPageState])
  return (
    <>
        <Row className='justify-content-center justify-content-md-between'>
            <Col xs={10} md={5} className='mb-4'>
            <TextInput
                Ref={searchRef}
                onKeyUp={onSearch}
            icon={<FontAwesomeIcon size="1x" icon={faMagnifyingGlass}/>} placeholder="search" name="search"/>   
            </Col>
            <Col xs={10} md={5}>
            <Select 
            Ref={limitRef}
            onChange={(e)=>{onLimitChange(e.target.value)}} icon={<FontAwesomeIcon size="1x" icon={faUpDown}/>}>
                {limitList.map((item)=>(
                    <option value={item}>{item}</option>
                ))}
            </Select>
            </Col>
        </Row>
        <Table striped>
          <thead>
            {
                tableHead
            }
          </thead>
          <tbody>
            {
                data.map(tableBody)
            }
          </tbody>
        </Table>
        <div className="d-flex justify-content-end mt-4">
            <div onClick={async()=>{setCurrentPage(1);await onPageChange(currentPageState);}} className="p-1 border border-1 cursor-pointer">
                {"|<"}
            </div>
            <div onClick={async()=>{setCurrentPage((n)=>{return n-1<=0?1:n-1});await onPageChange(currentPageState);}} className="p-1 border border-1 cursor-pointer">
                {"<<"}
            </div>
            {
                pageElement.map((item,index)=>(
                    <div onClick={async()=>{setCurrentPage(item);console.log(currentPageState);await onPageChange()}} className={`p-1 border border-1 cursor-pointer ${item==currentPageState?"bg-success":""}`}>
                        {item}
                    </div>
                ))
            }
            <div onClick={async()=>{setCurrentPage((n)=>{return n+1>maxPageState?maxPageState:n+1});await onPageChange(currentPageState);}} className="p-1 border border-1 cursor-pointer">
                {">>"}
            </div>
            <div onClick={async()=>{setCurrentPage(maxPageState);await onPageChange(currentPageState);}} className="p-1 border border-1 cursor-pointer">
                {">|"}
            </div>
        </div>
    </>
  );
}

export default ()=> {
    const [page,setPage]=useState(1);
    const [maxPage,setMaxPage]=useState(100);
    const data=[
        {no:1,firstname:'a',lastname:'b',username:'c'},
        {no:1,firstname:'a',lastname:'b',username:'c'},
        {no:1,firstname:'a',lastname:'b',username:'c'},
        {no:1,firstname:'a',lastname:'b',username:'c'},
        {no:1,firstname:'a',lastname:'b',username:'c'}
    ]
    return (
      <DataTables 
      onPageChange={()=>{console.log('page change')}} 
      currentPage={page} 
      maxPage={maxPage} 
      data={data}
      onLimitChange={(e)=>{
        console.log(e);
      }}
      limitList={[20,40,60,80,100]}
      tableHead={<tr>
              <th>#</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Username</th>
            </tr>}
       tableBody={(item,index)=>(
        <tr>
              <td>{item.no}</td>
              <td>{item.firstname}</td>
              <td>{item.lastname}</td>
              <td>{item.username}</td>
            </tr>
       )}
      />
    );
  }

