import { createSlice, configureStore } from '@reduxjs/toolkit'

export const counterSlice = createSlice({
  name: 'session',
  initialState: {
    token: "",
    staffId:"",
    userData:{
      fullname:"",
      _img:"",
      type: "P",
    },
    notification:0,
    page:"",
    sock:null
  },
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setUserData: (state, action) => {
      state.userData = action.payload
    },
    setSock: (state, action) => {
      state.sock = action.payload
    },
    setStaffId: (state, action) => {
      state.staffId = action.payload
    },
    setNotification: (state, action) => {
      state.notification = action.payload
    },
    setLogout:(state)=>{
      localStorage.removeItem('multiteam_token');
      state.token="";
      state.type = "";
    }
  },
})

// Action creators are generated for each case reducer function
export const { setToken,setNotification,setSock,setUserData,setStaffId, setLogout} = counterSlice.actions

export default configureStore({
  reducer: {
    session: counterSlice.reducer
  },
})