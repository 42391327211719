import { get } from "./Fetch";

const readAllCategory=async(id)=>{
    const req=await get("category");
    return req;
}

const readArticle=async(page,category,q=false)=>{
    const req=q?await get("article?page="+page+"&category="+category+"&q="+q):await get("article?page="+page+"&category="+category);
    return req;
}
export {readAllCategory,readArticle}