import { useEffect,useState } from "react"
import Banner from "../../Components/Public/Dashboard/Banner"
import AboutUs from "../../Components/Public/Dashboard/AboutUs"
import OurStaff from "../../Components/Public/Dashboard/OurStaff"
import EBook from "../../Components/Public/Dashboard/EBook"
import LearningCenter from "../../Components/Public/Dashboard/LearningCenter"
import JoinUs from "../../Components/Public/Dashboard/JoinUs"
import { readAll } from "../../Services/Dashboard"
import { Oval } from "react-loader-spinner"
import PublicLayout from "../../Layout/Index"
import { Helmet } from "react-helmet"
export default ()=>{
    const [loading,setLoading]=useState(true);
    const [data,setData]=useState({
        "newArticle":[],
        "randomArticle":[],
        "staff":[],
        "config":[],
    });
    const getData=async()=>{
        setLoading(true);
        let req=await readAll();
        setData(req.data);
        setLoading(false);
    }
    useEffect(()=>{
        window.AOS.init({
            offset:0
        });
        getData();
        
    },[])
    return (
    <>
        <Helmet>
            <title>Multiteam</title>
            <meta name="description" content="official website of multiteam community"/>
            <meta name="og:description" content="official website of multiteam community"/>
            <meta name="image" content={process.env.REACT_APP_BASE_URL+"img-config/banner-jpg"}/>
            <meta name="og:image" content={process.env.REACT_APP_BASE_URL+"img-config/banner-jpg"}/>
        </Helmet>
        <PublicLayout>
        {
            loading?
            <div className="d-flex align-items-center justify-content-center" style={{height:'90vh'}}>
                <Oval width={50} height={50} color="black"/>
            </div>
            :            
            <div style={{overflowX:'hidden'}}>
                <Banner data={data.config}/>
                <AboutUs data={data.config}/>
                <OurStaff data={data.staff}/>
                <LearningCenter newArticle={data.newArticle} randomArticle={data.randomArticle}/>
                <EBook newEBook={data.newEBook} randomEBook={data.randomEBook}/>
                <JoinUs/>
            </div>
        }
        </PublicLayout>
    </>
    )
}