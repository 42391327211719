import AdminLayout from "../../Layout/Index";
import { index } from "../../Services/Admin/Config";
import { useEffect, useState,useRef } from 'react';
import { Button, Card, Row ,Col, Table} from "react-bootstrap";
import { Oval } from "react-loader-spinner";
import ModalUpdate from "../../Components/Admin/Config/ModalUpdate";
import parser from 'html-react-parser'
import Swal from "sweetalert2"
export default ()=> {
    const [data,setData]=useState({});
    const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.onmouseenter = Swal.stopTimer;
          toast.onmouseleave = Swal.resumeTimer;
        }
      });
    const [showModalUpdate,setShowModalUpdate]=useState(false)
    const [loading,setLoading]=useState(true);
    const modalRef={
        data:{
            name:useRef(null),
            about_us:useRef(null),
            banner:useRef(null),
            logo:useRef(null),
            BannerImg:useRef(null),
            LogoImg:useRef(null),
        }
    }
    const getAllData=async()=>{
        setLoading(true);
        const req=await index()
        setData(req.data);
        setLoading(false);
    }
    useEffect(()=>{
        getAllData();
        
    },[])
    return (
      <AdminLayout>
        <ModalUpdate oldData={data} show={showModalUpdate} setShow={setShowModalUpdate} onSubmit={async()=>{await getAllData();setShowModalUpdate(false)}} Ref={modalRef.data}/>
        <Row>
            {
                loading
                ?
                <div className="d-flex justify-content-center">
                    <Oval width={30} height={30}/>
                </div>
                :
                <div className="p-4">
                    <Card>
                        <Card.Header>
                            Web Configuration
                        </Card.Header>
                        <Card.Body>
                            <Row className="mb-3">
                                <Col xs={12} className="fw-bold">
                                    Website Name
                                </Col>
                                <Col xs={12} className="border border-1 rounded-3 p-2">
                                    {data.name}
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col xs={12} className="fw-bold">
                                    About Us
                                </Col>
                                <Col xs={12} className="border border-1 rounded-3 p-2">
                                    {parser(data.about_us)}
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col xs={12} className="fw-bold">
                                    banner
                                </Col>
                                <Col xs={12} className="border border-1 rounded-3 p-2">
                                    <img src={data._banner?data._banner:""} style={{width:'300px',height:'300px'}}/>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col xs={12} className="fw-bold">
                                    web logo
                                </Col>
                                <Col xs={12} className="border border-1 rounded-3 p-2">
                                    <img src={data._logo?data._logo:""} style={{width:'300px',height:'300px'}}/>
                                </Col>
                            </Row>
                        </Card.Body>
                        <Card.Footer className="row justify-content-around">
                            <Button
                                variant="primary"
                                onClick={()=>{
                                    setShowModalUpdate(true);
                                    setTimeout(()=>{
                                        modalRef.data.about_us.current.setData(data.about_us)
                                        modalRef.data.BannerImg.current.src=data._banner
                                        modalRef.data.LogoImg.current.src=data._logo
                                    },500)}}
                            >
                                Update Configuration
                            </Button>
                        </Card.Footer>
                    </Card>
                </div>
            }
        
        </Row>
      </AdminLayout>
    );
  }

