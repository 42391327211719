import { useEffect,useRef,useState } from "react"
import PublicLayout from "../../Layout/Index"
import { Col, Row } from "react-bootstrap"
import { readOne } from "../../Services/Staff"
import { useParams } from "react-router-dom"
import { Oval } from "react-loader-spinner"
import * as brandsIcon from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Helmet } from "react-helmet"
export default ()=>{
    const [loading,setLoading]=useState(true);
    const [data,setData]=useState({});
    const {id}=useParams();
    const getStaff=async()=>{
        setLoading(true);
        const req=await readOne(id);
        setData(req.data)            
        setLoading(false);
    }
    useEffect(()=>{
        getStaff()
    },[])
    return (
    <>
    {!loading && 
    <Helmet>
        <title>Profile of {data.user.fullname}</title>
        <meta name="description" content={data.short_bio}/>
        <meta name="og:description" content={data.short_bio}/>
    </Helmet>
    }
    <PublicLayout>
        <div className="border border-1 p-2 my-4 mx-2">
        {
            loading?
            <div className="d-flex justify-content-center"> 
                <Oval width={20} height={20}/>
            </div>
            :
            <>
                <div className="fw-bold fs-2 text-center">
                {data.user.fullname}
                </div>
                <div className="text-center">
                    {data.short_bio}
                </div>
                <Row className="mt-3 justify-content-center justify-content-md-between">
                    <Col xs={10} md={4}>
                        <img src={data._img} style={{width:'100%'}}/>
                        <Row className="mt-3 justify-content-center">
                            {data.contacts.map((item,index)=>(
                                <a className="m-3 col-3" href={item.link} target="_blank">
                                    <FontAwesomeIcon size="2x" icon={brandsIcon[item.icon]}/>
                                </a>
                            ))}
                        </Row>
                    </Col>
                    <Col xs={10} md={7}>
                        {data.bio}
                    </Col>
                </Row>
            </>
        }
            
        </div>
    </PublicLayout>
    </>
    )
}