import { get,post,put,del } from "../Fetch"
const index=async ()=>{
    const req=await get("writer/personal");
    return req
}
const updateData=async (formRef)=>{
    const fr=new FormData();
    fr.append("username",formRef.username.current.value);
    fr.append("fullname",formRef.fullname.current.value);
    fr.append("img",formRef.img.current.files[0]);
    const req=await put("writer/personal/update-data",fr);
    return req
}

const updatePassword=async (formRef)=>{
    const fr=new FormData();
    fr.append("old",formRef.old.current.value);
    fr.append("password",formRef.password.current.value);
    fr.append("confirm",formRef.confirm.current.value);
    const req=await put("writer/personal/update-password",fr);
    return req
}
const deleteSession=async (id)=>{
    const fr=new FormData();
    const req=await del("writer/personal/delete-session/"+id);
    return req
}
const deleteAllSession=async (id)=>{
    const fr=new FormData();
    const req=await del("writer/personal/delete-all-session");
    return req
}

export {index,updateData,updatePassword,deleteSession,deleteAllSession}