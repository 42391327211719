import { useEffect } from "react";
import Member from "./Member";
import Public from "./Public";
import Admin from "./Admin";
import Writer from "./Writer";
import { useSelector } from "react-redux";
export default ({children})=>{
    const userData=useSelector(s=>s.session.userData)
    useEffect(()=>{
        console.log(userData)
    },[])
    return (<>
        {
            userData.type=="P" &&
            <Public>
                {children}
            </Public>
        }
        {
            userData.type=="W" &&
            <Writer>
                {children}
            </Writer>
        }
        {
            userData.type=="A" &&
            <Admin>
                {children}
            </Admin>
        }
        {
            (userData.type!="A"&&userData.type!="W"&&userData.type!="P") &&
            <Member>
                {children}
            </Member>
        }
        </>
    )
}