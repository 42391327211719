import WriterLayout from "../../Layout/Index";
import { index,deleteSession,deleteAllSession } from "../../Services/Writer/Personal";
import { useEffect, useState,useRef } from 'react';
import { Button, Card, Row ,Col, Table} from "react-bootstrap";
import { Oval } from "react-loader-spinner";
import ModalUpdateData from "../../Components/Writer/Personal/ModalUpdateData";
import ModalUpdatePassword from "../../Components/Writer/Personal/ModalUpdatePassword";
import { setUserData } from "../../store";
import {useSelector,useDispatch} from "react-redux"
import Swal from "sweetalert2"
export default ()=> {
    const [data,setData]=useState({
        profile:{},
        session:[]
    });
    const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.onmouseenter = Swal.stopTimer;
          toast.onmouseleave = Swal.resumeTimer;
        }
      });
    const dispatch=useDispatch();
    const [showModalUpdateData,setShowModalUpdateData]=useState(false)
    const [showModalUpdatePassword,setShowModalUpdatePassword]=useState(false);
    const [loading,setLoading]=useState(true);
    const modalRef={
        data:{
            username:useRef(null),
            fullname:useRef(null),
            img:useRef(null),
        },
        password:{
            old:useRef(null),
            password:useRef(null),
            confirm:useRef(null),
        }
    }
    const confirmLogout=async(id)=>{
        Swal.fire({
            title: 'Confirmation',
            text: `Logout from this session?`,
            icon:'question',
            showCancelButton: true,
            confirmButtonText: "Yes",
            cancelButtonText: "Cancel",
        }).then(async (e) => {
            if(e.isConfirmed){
                setLoading(true)
                const req=await deleteSession(id);
                Toast.fire({
                    icon: req.code==200?"success":"danger",
                    title: "Session Has Been Deleted"
                });  
                setLoading(false)
                await getAllData()
            }
        })
        
    }
    const confirmLogoutAll=async(id)=>{
        Swal.fire({
            title: 'Confirmation',
            text: `Logout from all session? note:your current session will stay intact`,
            icon:'question',
            showCancelButton: true,
            confirmButtonText: "Yes",
            cancelButtonText: "Cancel",
        }).then(async (e) => {
            if(e.isConfirmed){
                setLoading(true)
                const req=await deleteAllSession();
                Toast.fire({
                    icon: req.code==200?"success":"danger",
                    title: "Session Has Been Deleted"
                });  
                setLoading(false)
                await getAllData()
            }
        })
        
    }
    const getAllData=async()=>{
        setLoading(true);
        const req=await index()
        setData(req.data);
        dispatch(setUserData(req.data.profile));
        setLoading(false);
    }
    useEffect(()=>{
        getAllData();
        
    },[])
    return (
      <WriterLayout>
        <ModalUpdateData oldData={data.profile} show={showModalUpdateData} setShow={setShowModalUpdateData} onSubmit={async()=>{await getAllData();setShowModalUpdateData(false)}} Ref={modalRef.data}/>
        <ModalUpdatePassword show={showModalUpdatePassword} setShow={setShowModalUpdatePassword} onSubmit={async()=>{await getAllData();setShowModalUpdatePassword(false)}} Ref={modalRef.password}/>
        <Row>
            {
                loading
                ?
                <div className="d-flex justify-content-center">
                    <Oval width={30} height={30}/>
                </div>
                :
                <div className="p-4">
                    <Card>
                        <Card.Header>
                            Profile Data
                        </Card.Header>
                        <Card.Body>
                            <Row className="mb-3">
                                <Col xs={12} className="fw-bold">
                                    Username
                                </Col>
                                <Col xs={12} className="border border-1 rounded-3 p-2">
                                    {data.profile.username}
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col xs={12} className="fw-bold">
                                    Fullname
                                </Col>
                                <Col xs={12} className="border border-1 rounded-3 p-2">
                                    {data.profile.fullname}
                                </Col>
                            </Row>
                        </Card.Body>
                        <Card.Footer className="row justify-content-around">
                            <Button
                                variant="primary"
                                onClick={()=>{setShowModalUpdateData(true)}}
                            >
                                Update Profile Data
                            </Button>
                            <Button
                                variant="primary"
                                onClick={()=>{setShowModalUpdatePassword(true)}}
                            >
                                Update Password
                            </Button>
                        </Card.Footer>
                    </Card>
                    <Card className="mt-4">
                        <Card.Header>
                            Login Data
                        </Card.Header>
                        <Card.Body>
                            <Table bordered>
                                <tr>
                                    <th>No</th>
                                    <th>Device</th>
                                    <th>IP</th>
                                    <th>Login Date</th>
                                    <th>Option</th>
                                </tr>
                                {
                                    data.session.map((item,index)=>(
                                        <tr>
                                            <td>{index+1}</td> 
                                            <td>{item.device}</td>
                                            <td>{item.ip}</td>
                                            <td>{item._created_at}</td>
                                            <td>
                                                <Button 
                                                    variant="outline-danger"
                                                    onClick={()=>{confirmLogout(item.id)}}
                                                >
                                                    Logout
                                                </Button>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </Table>
                        </Card.Body>
                        <Card.Footer className="row justify-content-around">
                            <Button
                                variant="danger"
                                onClick={()=>{confirmLogoutAll()}}
                            >
                                Logout From All Session
                            </Button>
                        </Card.Footer>
                    </Card>
                </div>
            }
        
        </Row>
      </WriterLayout>
    );
  }

