import axios from 'axios';
const API_URL = process.env.REACT_APP_API_URL;
const token=localStorage.getItem('token_multiteam_web');
const get = async (endpoint) => {
  try {
    const response = await axios.get(API_URL + endpoint,{headers:{
      'Authorization':"Bearer "+token
    }});
    
    return response.data;
  } catch (error) {
    // Handle error
    console.log(error)
    // console.error('Error:', error.response.data);
    return error.response.data;
  }
}
const post = async (endpoint,form) => {
  try {
    const response = await axios.post(API_URL + endpoint,form,{
      headers:{
        'Content-Type': 'multipart/form-data',
        "Authorization":"Bearer "+token
      }
    });
    return response.data;
  } catch (error) {
    // Handle error
    console.log(error)
    // console.error('Error:', error.response.data);
    return error.response.data;
  }
}
const put = async (endpoint,form) => {
  try {
    const response = await axios.put(API_URL + endpoint,form,{
      headers:{
        'Content-Type': 'multipart/form-data',
        "Authorization":"Bearer "+token
      }
    });
    return response.data;
  } catch (error) {
    // Handle error
    console.log(error)
    // console.error('Error:', error.response.data);
    return error.response.data;
  }
}
const del = async (endpoint) => {
  try {
    const response = await axios.delete(API_URL + endpoint,{
      headers:{
        "Authorization":"Bearer "+token
      }
    });
    return response.data;
  } catch (error) {
    // Handle error
    console.log(error)
    // console.error('Error:', error.response.data);
    return error.response.data;
  }
}
export { get,post,put,del };