import { useEffect, useRef, useState } from "react";
import Footer from "../Components/Shared/Footer";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars, faCaretDown,faBell} from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import {useSelector} from "react-redux"
import MenubarItem from "../Components/Shared/Layout/MenubarItem";
import { logout } from "../Services/Auth";
import ModalNotification from "../Components/Shared/ModalNotification";
const MenubarDropdown=({stick,children,label})=>{
    const [showDropdown,setShowDropdown]=useState(false);
    return (
        <div className={`menubar-item cursor-pointer`} onClick={()=>{setShowDropdown(n=>!n)}}>
            <div className="d-flex justify-content-between">
                {label}
            </div>
            <div className={`${showDropdown?"active":""} ${stick?'bg-white text-black':'bg-dark text-light'} menubar-dropdown-container`} >
                {children}
            </div>
        </div>
    )
}
export default({children})=>{
    const [stick,setStick]=useState(false);
    const [showMenu,setShowMenu]=useState(false);
    const userData=useSelector(state=>state.session.userData);
    const [showModal,setShowModal]=useState(false);
    const notification=useSelector(s=>s.session.notification)
    const sock=useSelector(s=>s.session.sock)
    const handleScroll=()=>{
        let scrollNow=window.scrollY;
        setStick(scrollNow>=120);
    }
    const logOut=async()=>{
        Swal.fire({
            title: 'Confirmation',
            text: 'Are you sure you want to logout ?',
            icon:'question',
            showCancelButton: true,
            confirmButtonText: "Logout",
            cancelButtonText: "Cancel",
        }).then(async (e) => {
            if(e.isConfirmed){
                sock.emit("logout")
                await logout();
                localStorage.setItem("token_multiteam_web","")
                setTimeout(()=>{
                    window.location.href=process.env.REACT_APP_BASE_URL;
                },1000)
            }
        })
        
    }
    useEffect(()=>{
        window.addEventListener('scroll', handleScroll);
        return ()=>{
            window.removeEventListener('scroll',handleScroll)
        }
    },[])
    return(
    <>
    <ModalNotification show={showModal} setShow={setShowModal}/>
    <div>
      <div className="main">
        <div style={{top:'0px',zIndex:'3',boxShadow:'0px 1px 10px 1px black'}} 
        className={`${stick?'position-fixed w-100 bg-white text-black':'position-relative bg-dark text-light'} d-block d-lg-flex justify-content-lg-between`}>
            <div className="d-flex justify-content-lg-center justify-content-between p-2 align-items-center">
                <div>Multiteam</div>
                <div className="d-flex align-items-center">
                    <div className="d-block d-lg-none cursor-pointer" onClick={()=>setShowMenu(n=>!n)}>
                        <FontAwesomeIcon size="2x" icon={faBars}/>
                    </div>
                    <div onClick={async()=>{setShowModal(true)}} className="cursor-pointer d-lg-none d-flex align-items-center mx-3">
                        <FontAwesomeIcon icon={faBell} />
                        <div>{notification}</div>
                    </div>
                </div>
                
            </div>
            <div className={showMenu?'d-block active menu-item d-lg-flex justify-content-between':'d-block menu-item d-lg-flex justify-content-between'}>
                <MenubarItem  label="Home" to='/'/>
                <MenubarItem  label="Our Staff" to='/staff'/>
                <MenubarItem  label="E-book" to='/ebook'/>
                <MenubarItem  label="Learning Center" to='/learning-center'/>
                <MenubarItem  label="Join Us" to='/'/>
                <MenubarDropdown stick={stick} label={
                    <>
                        <img src={userData._img} style={{width:'25px',height:'25px',borderRadius:'20px'}}/>
                        <div className="ms-2">{userData.fullname}</div>
                        <div className="ms-2">
                            <FontAwesomeIcon icon={faCaretDown}/>
                        </div>
                    </>
                }>
                    <MenubarItem  label="Profile" to='/Profile'/>
                    <MenubarItem  label="Logout" onClick={()=>{logOut()}}/>
                </MenubarDropdown>
                <div onClick={async()=>{setShowModal(true)}} className="cursor-pointer d-none d-lg-flex align-items-center me-4">
                    <FontAwesomeIcon icon={faBell} />
                    <div>{notification}</div>
                </div>
            </div>
        </div>
        {children}
      </div>
      <Footer/>
    </div>
    </>
    )
}