import { Col, Row,Button} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
export default ({data})=>{
    const nav=useNavigate();
    return (
        <Row className='mt-4 ' data-aos="fade-right">
            <Col xs={12} className='text-center'>
                <div className="fs-1">Our Staff</div>
                <div>
                    <Button variant="success">See All</Button>
                </div>
            </Col>
            <Col xs={12} className='text-center mt-4'>
                <div id="demo" className="carousel slide" data-bs-ride="carousel">
                    <div className="carousel-inner">
                        {
                            data.map((item,index)=>(
                                <div key={index} className={index==0?"carousel-item active":"carousel-item"}>
                                    <Row className="justify-content-center">
                                        <Col xs={10} className="border border-1 rounded-2">
                                            <Row className='justify-content-center justify-content-sm-around'>
                                                <Col xs={12} sm={4} md={2}>
                                                    <img className="rounded-2" src={item._img} style={{width:'100%'}}/>
                                                </Col>
                                                <Col xs={12} sm={5} md={10}>
                                                    <Row className="align-items-center h-100">
                                                        <h2 className="w-100 text-center">{item.user.fullname}</h2>
                                                        <p className="w-100 text-center">{item.short_bio}</p>
                                                        <Row className="justify-content-center justify-content-md-around">
                                                            <Col xs={12} md={4} className='mb-4 mb-md-0'>
                                                                <Button onClick={()=>{
                                                                    nav("/staff-detail/"+item.id)
                                                                }} variant="primary" className="w-100">Detail</Button>
                                                            </Col>
                                                            <Col xs={12} md={4} className='mb-4 mb-md-0'>
                                                                <a href={item.portfolio_link} target='_blank'>
                                                                    <Button variant="success" className="w-100">View Portfolio</Button>
                                                                </a>
                                                            </Col>
                                                            
                                                        </Row>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Col>
                                    
                                    </Row>
                                </div>
                            ))
                        }
                    </div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#demo" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon"></span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#demo" data-bs-slide="next">
                    <span className="carousel-control-next-icon"></span>
                    </button>
                </div>
            </Col>
        </Row>
    )
}