import {Row,Col, Button, Card} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub,faGoogle } from '@fortawesome/free-brands-svg-icons';
import { useNavigate } from 'react-router-dom';

import { useRef, useState } from 'react';

export default ()=>{
    
    const nav=useNavigate();
    
    return (
        <div style={{height:'100vh'}} className='d-flex justify-content-center align-items-center'>
            <Col xs={10} md={7} lg={4}>
                <Card>
                    <Card.Header>
                        <h3 className='text-center'>Login To Multiteam</h3>
                    </Card.Header>
                    <Card.Body>
                        <a href="#" className='w-100 cursor-pointer d-flex align-items-center p-2 m-2 border border-solid rounded-3'>
                            <FontAwesomeIcon icon={faGoogle} size='2x'/>
                            <span className='ms-4'> Login with google (coming soon)</span>
                        </a>
                        <a href={"https://github.com/login/oauth/authorize?client_id="+process.env.REACT_APP_GITHUB_CLIENT_ID+"&scope=user:email"} className='w-100 cursor-pointer d-flex align-items-center p-2 m-2 border border-solid rounded-3'>
                            <FontAwesomeIcon icon={faGithub} size='2x'/>
                            <span className='ms-4'> Login with github</span>
                        </a>
                    </Card.Body>
                    <Card.Footer>
                        <div className='d-flex w-100 justify-content-center'>
                            
                            <Button onClick={()=>{nav("/")}} variant='secondary' className='ms-3'>
                                Kembali
                            </Button>
                        </div>
                    </Card.Footer>
                </Card>
            </Col>
        </div>
    )
}