import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
export default ({label,icon,to,showMenu,onClick})=>{
    const nav=useNavigate();
    return (
        (icon!=null&&showMenu!=null)
        ?
        <div onClick={()=>{nav(to)}} className={`m-2 p-3 cursor-pointer border border-1 rounded-3 d-flex align-items-center ${!showMenu?"justify-content-center":""}`}>
            <div><FontAwesomeIcon icon={icon}/></div>
            <div className={`${showMenu?"d-block ms-2":"d-none"}`}>{label}</div>
        </div>
        :
        <div className="menubar-item cursor-pointer" onClick={()=>{onClick?onClick():nav(to)}}>{label}</div>
    )
}