import { useState } from "react"
import { Oval } from "react-loader-spinner"
import { Button } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBook, faPencil,faTrash } from "@fortawesome/free-solid-svg-icons"
import { readOne,deleteData } from "../../../Services/Admin/EBook";
import Swal from 'sweetalert2'
export default ({item,updateRef,showModalState,onDeleted})=>{
    const [loading,setLoading]=useState(false);
    const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.onmouseenter = Swal.stopTimer;
          toast.onmouseleave = Swal.resumeTimer;
        }
      });
    const getOneData=async()=>{
        setLoading(true);
        const req=await readOne(item.id);
        if(req.code==200){
            showModalState.update(true)
            setTimeout(()=>{
                console.log(updateRef)
                updateRef.id.current=req.data.id;
                updateRef.name.current.value=req.data.name
                updateRef.link.current.value=req.data.link
                updateRef.description.current.value=req.data.description               
                updateRef.ImgRef.current.src=req.data._img?req.data._img:""
            },100)
        }
        setLoading(false)
    }
    const confirmDelete=async()=>{
        Swal.fire({
            title: 'Confirmation',
            text: `Delete This E-Book : ${item.name}?`,
            icon:'question',
            showCancelButton: true,
            confirmButtonText: "Yes",
            cancelButtonText: "Cancel",
        }).then(async (e) => {
            if(e.isConfirmed){
                setLoading(true)
                const req=await deleteData(item.id);
                Toast.fire({
                    icon: req.code==200?"success":"danger",
                    title: req.message
                });  
                setLoading(false)
                await onDeleted();
            }
        })
        
    }
    return (
        <>
        {
            loading?
            <div className="d-flex w-100 justify-content-center">
                <Oval width={30} height={30}/>
            </div>
            :
            <div className="row">
                <Button className="m-2" onClick={async()=>{getOneData()}} variant="secondary">
                    <FontAwesomeIcon icon={faPencil}/>
                    <span className="ms-2">Edit Data</span>
                </Button>
                <a href={item.link} target="_blank">
                    <Button className="m-2" variant="success">
                        <FontAwesomeIcon icon={faBook}/>
                        <span className="ms-2">Preview E-Book</span>
                    </Button>
                </a>
                <Button className="m-2" variant="danger" onClick={async()=>{confirmDelete()}}>
                    <FontAwesomeIcon icon={faTrash}/>
                    <span className="ms-2">Delete Data</span>
                </Button>
            </div>
        }
        </>
    )
}