import {useNavigate} from "react-router-dom"
import { Col,Row,Button } from "react-bootstrap"
export default ({data})=>{
    const nav=useNavigate()
    return (
        <Col xs={10} lg={5} className="border border-1 rounded-2 mt-4">
            <Row>
                <Col xs={12} sm={4}>
                    <img src={data._img} className="w-100 rounded-2" style={{height:'200px'}}/>
                </Col>
                <Col xs={12} sm={8} className="p-3">
                    <b>{data.user.fullname}</b>
                    <div>{data.short_bio}</div>
                    <Row className="justify-content-center justify-content-md-around">
                        <Col xs={12} md={4} className='mb-4 mb-md-0'>
                            <Button onClick={()=>{
                                nav("/staff-detail/"+data.id)
                            }} variant="primary" className="w-100">Detail</Button>
                        </Col>
                        <Col xs={12} md={4} className='mb-4 mb-md-0'>
                            <a href={data.portfolio_link} target='_blank'>
                                <Button variant="success" className="w-100">View Portfolio</Button>
                            </a>
                        </Col>
                    </Row>
                </Col>
            </Row>
                               
        </Col>
    )
}