import {useNavigate} from "react-router-dom"
export default ({data})=>{
    return (
        <a href={data.link} target="_blank" className="cursor-pointer col-10 col-sm-5 col-lg-3 border border-1 rounded-2 m-2">
            <div>
                <img src={data._img} className="w-100 rounded-2" style={{height:'200px'}}/>
                <div className="p-3">
                    <b>{data.name}</b>
                    <div>author : {data.user.fullname}</div>
                    <div>date : {data._date_created}</div>
                </div>
            </div>
        </a>
    )
}