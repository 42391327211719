import { Modal,Button } from "react-bootstrap"
import TextInput from "../../Shared/TextInput"
import TextArea from "../../Shared/TextArea"
import { useState } from "react"
import { faBook, faGlobe, faLock, faUser, faUserAstronaut, faUserTie } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { addData } from "../../../Services/Admin/EBook"
import { Oval } from "react-loader-spinner"
import Swal from 'sweetalert2'
import ImageUpload from "../../Shared/ImageUpload"
export default ({Ref,show,setShow,onSubmit})=>{
    const [errorMessage,setErrorMessage]=useState({
        name:"",
        link:"",
        description:"",
    });
    const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.onmouseenter = Swal.stopTimer;
          toast.onmouseleave = Swal.resumeTimer;
        }
      });      
    const [loading,setLoading]=useState(false);
    const submitData=async()=>{
        setLoading(true);
        const req=await addData(Ref);        
        if(req.code!=200){
            if(Array.isArray(req.message)){
                setErrorMessage(req.message.reduce((acc,current)=>{return {...acc,...current};},{}))
            }
            else{
                setErrorMessage({
                    name:"",
                    link:"",
                    description:"",
                    other:req.message
                })
            }
        }
        else{
            Toast.fire({
                icon: "success",
                title: req.message
            });           
            await onSubmit();    
        }
        setLoading(false);
        
    }
    return (
    <Modal size="md" show={show} onHide={()=>{setShow(false)}}>
        <Modal.Header closeButton>
        <Modal.Title>Add E-Book</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <TextInput errorMessage={errorMessage.name} Ref={Ref.name} label="E-Book Name" placeholder="E-Book Name" icon={<FontAwesomeIcon icon={faBook} size='1x'/>}/>
            <TextInput errorMessage={errorMessage.link} Ref={Ref.link} label="Link" placeholder="Link" icon={<FontAwesomeIcon icon={faGlobe} size='1x'/>}/>
            <TextArea errorMessage={errorMessage.description} Ref={Ref.description} label="Description" placeholder="Description"/>
            <ImageUpload Ref={Ref.img} label="Picture"/>
            {
                errorMessage.other!=""
                &&
                <div className="w-100 text-center text-danger">
                    {errorMessage.other}
                </div>
            }
        </Modal.Body>
        <Modal.Footer>
            {
                loading?
                <Oval width={30} height={30}/>
                :
                <>
                    <Button variant="secondary" onClick={()=>{setShow(false)}}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={async()=>{await submitData()}}>
                        Save Changes
                    </Button>
                </>
            }
        </Modal.Footer>
    </Modal>)
}