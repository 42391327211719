import CKEditor from "../../Components/Shared/CKEditor/CKEditor"
import { useRef } from "react"
export default ()=>{
    const editRef=useRef();
    return (
        <div className="mt-4">
            <CKEditor editorRef={editRef}/>
            <div>
                <input type="button" className="btn btn-primary" value="lihat hasil" onClick={()=>{console.log(editRef.current.data.get())}}/>
            </div>
        </div>
    )
}