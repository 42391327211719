import { useEffect, useState } from "react";
import { Routes,Route } from "react-router-dom";
import PublicRoute from "./Routes/Public";
import AdminRoute from "./Routes/Admin";
import WriterRoute from "./Routes/Writer";
import {useSelector,useDispatch} from "react-redux"
import { setUserData,setSock,setNotification } from "./store";
import { checkUser } from "./Services/Auth";
import { useLocation } from "react-router-dom";
import io from 'socket.io-client';
import { checkNotification } from "./Services/Notification";
const sock_io=io(process.env.REACT_APP_SOCKET_URL)
function App() {
  const dispatch=useDispatch();
  const params=useLocation();
  const userData=useSelector(state=>state.session.userData);
  const [loading,setLoading]=useState(false);
  const checkToken=()=>{
    const token=localStorage.getItem('token_multiteam_web');
    sock_io.emit("sign_in",token)
  }
  const getNotification=async()=>{
    const req=await checkNotification();
    dispatch(setNotification(req.data))
  }
  const doCheckUser=async()=>{
    setLoading(true)
    const urlParams=new URLSearchParams(params.search);
    const newToken=urlParams.get("token");
    if(newToken){
      localStorage.setItem('token_multiteam_web',newToken)
      window.location.href=process.env.REACT_APP_BASE_URL;
    }
    const req=await checkUser();
    dispatch(setUserData(req.data));
    checkToken();
    await getNotification()
    setLoading(false)
}
  useEffect(()=>{
    window.AOS.init({ // Initialization
      duration: 500,
      once: false,   // Whether animation should happen only once
      mirror: false, 
    });
    sock_io.on('check_notification',async()=>{
      await getNotification()
    })
    
    dispatch(setSock(sock_io));
    doCheckUser();
  },[])
  return (
    <>
    {
      loading?
      <div>Please Wait</div>
      :
      <Routes>
        {
            (userData.type!="A"&&userData.type!="W")&&PublicRoute.map((item,index)=>(
                <Route key={index} path={item.path} element={item.element}/>
            ))
        }
        {
            userData.type=="A"&&AdminRoute.map((item,index)=>(
                <Route key={index} path={item.path} element={item.element}/>
            ))
        }
        {
            userData.type=="W"&&WriterRoute.map((item,index)=>(
                <Route key={index} path={item.path} element={item.element}/>
            ))
        }
    </Routes>  
    }
    </>    

  );
}

export default App;
