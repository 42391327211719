import { useEffect,useRef,useState } from "react"
import Box from "../../Components/Public/Staff/Box"
import Filter from "../../Components/Public/Staff/Filter"
import {readAll} from "../../Services/Staff"
import { Oval } from "react-loader-spinner"
import PublicLayout from "../../Layout/Index"
import { Helmet } from "react-helmet"
export default ()=>{
    let page=useRef(1);
    let maxPageRef=useRef(1);
    let searchRef=useRef(null);
    const [data,setData]=useState([]);
    const [pageElement,setPageElement]=useState([]);
    const [loading,setLoading]=useState(true);
    const readStaff=async()=>{
        setLoading(true);
        const req=await readAll(page.current,searchRef.current.value!=""?searchRef.current.value:false);
        maxPageRef.current=req.meta.pagination.maxPage;
        let maxPage=page.current+2;
        let minPage=page.current-2;
        
        if(minPage<=0){
            minPage=1;
        }
        if(page.current<=3){
            maxPage=5;
        }
        if(maxPage>maxPageRef.current){
            maxPage=maxPageRef.current;
        }
        
        setPageElement((n)=>{
            let newElement=[];
            for(let i=minPage;i<=maxPage;i++){
                newElement.push(i);
            }
            return newElement;
        })
        setData(req.data)            
        setLoading(false);
    }
    useEffect(()=>{
        readStaff()
        document.title="Staff"
    },[])
    return (
    <>
        <Helmet>
            <title>Staff</title>
            <meta name="description" content="official website of multiteam community"/>
            <meta name="og:description" content="official website of multiteam community"/>
            <meta name="image" content={process.env.REACT_APP_BASE_URL+"img-config/banner-jpg"}/>
            <meta name="og:image" content={process.env.REACT_APP_BASE_URL+"img-config/banner-jpg"}/>
        </Helmet>
        <PublicLayout>
        <Filter
            page={page}
            searchRef={searchRef}
            readStaff={readStaff}
        />
        <div className="row justify-content-around">
            {
                loading?
                <div className="d-flex justify-content-center"> 
                    <Oval width={20} height={20}/>
                </div>
                :
                <>
                {
                    data.map((item,index)=>(
                        <Box data={item} key={index}/>
                    ))
                }
                </>
            }
        </div>
        <div className="d-flex justify-content-center mt-4">
            <div onClick={async()=>{page.current=1;await readStaff();}} className="p-1 border border-1 cursor-pointer">
                {"|<"}
            </div>
            <div onClick={async()=>{page.current=page.current-1<=0?1:page.current-1;await readStaff();}} className="p-1 border border-1 cursor-pointer">
                {"<<"}
            </div>
            {
                pageElement.map((item,index)=>(
                    <div onClick={async()=>{page.current=item;await readStaff()}} className={`p-1 border border-1 cursor-pointer ${item==page.current?"bg-success":""}`}>
                        {item}
                    </div>
                ))
            }
            <div onClick={async()=>{page.current=page.current+1>maxPageRef.current?maxPageRef.current:page.current+1;await readStaff();}} className="p-1 border border-1 cursor-pointer">
                {">>"}
            </div>
            <div onClick={async()=>{page.current=maxPageRef.current;await readStaff();}} className="p-1 border border-1 cursor-pointer">
                {">|"}
            </div>
        </div>
    </PublicLayout>
    </>
    )
}