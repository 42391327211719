import React from 'react';
import AsyncSelect from 'react-select/async';

export default ({Ref,icon,errorMessage,loadOptions,label,placeholder,optionsMap}) => {
    const fetchData = async (inputValue) => {        
        try {
            const req = await loadOptions(inputValue);
            const data = await req.data;
        
            // Map the API response to the format expected by react-select
            // return data.results.map((item) => ({
            // value: item.id,
            // label: item.name,
            // }));
            return data.map(optionsMap);
        } catch (error) {
            console.error('Error fetching data:', error);
            return [];
        }
        };
    
  return (
    <div className='w-100'>
      {label && <label>{label}</label>}
      <div className={`d-flex ${errorMessage && "border-danger"} border border-1 rounded-3 w-100 align-items-center custom-input p-1 m-1`}>
            {icon}
        <AsyncSelect
          cacheOptions
          styles={{
            container: (provided) => ({
              ...provided,
              width:'100%'})
          }}
          loadOptions={fetchData}
          defaultOptions // Show default options before typing
          placeholder={placeholder}
          defaultValue={
            Ref.current?.value?
            {
              "value":Ref.current.value,
              "label":Ref.current.label
            }:
            {}
          }
          onChange={(e)=>{
            Ref.current=e
          }}   
        />
        </div>
        {errorMessage!=""&&
            <div className="text-danger mt-3">
                {errorMessage}
            </div>
            }
    </div>
  );
};


