import { Col, Row,Button} from 'react-bootstrap';
export default ()=>{
    return (
    <Row className='row p-4 align-items-center ' data-aos="fade-right">
        <Col xs={12}>
          <div className='fs-1 w-100 text-center'>Want To Be Part Of Multiteam?</div>
          <div className='w-100 '>Do you want to join multiteam? Do you want to learn and sharing something new about information technology? Do you want to looking for a friend that has the same passion with you?</div>
          <div className='d-flex justify-content-center'>
            <Button variant="primary">
              Contact Admin
            </Button>
          </div>
        </Col>
    </Row>
    )
}