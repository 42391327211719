import { get,post,put,del } from "../Fetch"
const readAll=async (limit,page,query)=>{
    const req=await get("admin/staff?limit="+limit+"&page="+page+"&q="+query);
    return req
}
const readOne=async (id)=>{
    const req=await get("admin/staff/"+id);
    return req
}

const addData=async (formRef)=>{
    const fr=new FormData();
    fr.append("username",formRef.username.current.value);
    fr.append("fullname",formRef.fullname.current.value);
    fr.append("password",formRef.password.current.value);
    fr.append("confirm",formRef.confirm.current.value);
    fr.append("bio",formRef.bio.current.value);
    fr.append("short_bio",formRef.short_bio.current.value);
    fr.append("img",formRef.img.current.files[0]);
    const req=await post("admin/staff",fr);
    return req
}
const updateData=async (formRef)=>{
    const fr=new FormData();
    fr.append("username",formRef.username.current.value);
    fr.append("fullname",formRef.fullname.current.value);
    fr.append("bio",formRef.bio.current.value);
    fr.append("short_bio",formRef.short_bio.current.value);
    fr.append("img",formRef.img.current.files[0]);
    const req=await put("admin/staff/update-data/"+formRef.id.current,fr);
    return req
}
const updatePassword=async (formRef)=>{
    const fr=new FormData();
    fr.append("password",formRef.password.current.value);
    fr.append("confirm",formRef.confirm.current.value);
    const req=await put("admin/staff/update-password/"+formRef.id.current,fr);
    return req
}
const deleteData=async (id)=>{
    const req=await del("admin/staff/"+id);
    return req
}

export {readAll,addData,updateData,updatePassword,readOne,deleteData}