import Dashboard from "../Pages/Admin/Dashboard";
import Category from "../Pages/Admin/Category";
import Staff from "../Pages/Admin/Staff";
import EBook from "../Pages/Admin/EBook";
import Article from "../Pages/Admin/Article";
import PreviewArticle from "../Pages/Admin/PreviewArticle";
import Personal from "../Pages/Admin/Personal";
import Member from "../Pages/Admin/Member";
import Visitor from "../Pages/Admin/Visitor";
import Config from "../Pages/Admin/Config";
import Contact from "../Pages/Admin/Contact";
import AllNotification from "../Pages/Shared/AllNotification";
const NotFound=()=>{
  return (
    <div className="w-100 justify-content-center d-flex align-items-center" style={{height:'100vh'}}>
      <div className="border border-1 rounded-3 justify-content-center d-flex align-items-center p-3">
        Page Not Found
      </div>
    </div>
  )
}

const route = [
  {
    'path': '/',
    'element': <Dashboard />
  },
  {
    'path': '/notification',
    'element': <AllNotification />
  },
  {
    'path': 'category',
    'element': <Category />
  },

  {
    'path': 'personal',
    'element': <Personal />
  },
  {
    'path': 'staff',
    'element': <Staff />
  },
  {
    'path': 'article',
    'element': <Article />
  },
  {
    'path': 'read/:id',
    'element': <PreviewArticle />
  },
  {
    'path': 'ebook',
    'element': <EBook />
  },
  {
    'path': 'visitor',
    'element': <Visitor />
  },
  {
    'path': 'member',
    'element': <Member />
  },
  {
    'path': 'config',
    'element': <Config />
  },
  {
    'path': 'contact/:staff_id',
    'element': <Contact />
  },
  {
    'path': '*',
    'element': <NotFound />
  }
  
];
export default route;