export default({icon,Ref,errorMessage,label,name,children,onChange})=>{
    return (
        <div className="mb-3">{label&& <label className="ms-3">{label}</label>}
        <div className={`d-flex ${errorMessage && "border-danger"} border border-1 rounded-3 w-100 align-items-center custom-input p-1 m-1`}>
            {icon}
            <div className="d-flex position-relative ms-2 w-100">
                <select ref={Ref} onChange={onChange} className="w-100" name={name} >
                    {children}
                </select>
            </div>
            
        </div>
        {errorMessage!=""&&
            <div className="text-danger mt-3">
                {errorMessage}
            </div>
            }
        </div>
    )
}