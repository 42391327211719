import { get,post,put,del } from "../Fetch"
const index=async ()=>{
    const req=await get("admin/config");
    return req
}
const update=async (formRef)=>{
    const fr=new FormData();
    fr.append("name",formRef.name.current.value);
    fr.append("about_us",formRef.about_us.current.getData());
    fr.append("banner_img",formRef.banner.current.files[0]);
    fr.append("logo",formRef.logo.current.files[0]);
    const req=await put("admin/config",fr);
    return req
}

export {index,update}