import {useNavigate} from "react-router-dom"
import { Col } from "react-bootstrap";
export default ({data})=>{
    const nav=useNavigate();
    return (
        <Col xs={12} sm={5} lg={3} onClick={()=>{nav('/read/'+data._slug)}} className="m-2 cursor-pointer border border-1 rounded-2">
            <img src={data._img} className="w-100 rounded-2" style={{height:'200px'}}/>
            <div className="p-3">
                <b>{data.title}</b>
                <div>author : {data.user.fullname}</div>
                <div>date : {data._date_created}</div>
            </div>
        </Col>
    )
}