import { useEffect,useState } from "react";
import { useParams} from "react-router-dom";
import { prevOne } from "../../Services/Admin/Article";
import { Oval } from "react-loader-spinner";
import parser from "html-react-parser"
import AdminLayout from "../../Layout/Index";
import { Card } from "react-bootstrap";
import 'ckeditor5/ckeditor5.css';
export default ()=>{
    useEffect(()=>{
        window.scrollTo(0,0);
    },[])
    return (
    <AdminLayout>
        <div className="w-100 p-3">
            this page is under construction
        </div>
    </AdminLayout>
    )
}