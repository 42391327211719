import WriterLayout from "../../Layout/Index";
import DataTables from "../../Components/Shared/DataTables";
import { readAll } from "../../Services/Writer/Contact";
import { useEffect, useState,useRef } from 'react';
import { Button, Row } from "react-bootstrap";
import { Oval } from "react-loader-spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as brandsIcon from "@fortawesome/free-brands-svg-icons";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import ModalAdd from "../../Components/Writer/Contact/ModalAdd";
import ModalUpdate from "../../Components/Writer/Contact/ModalUpdate";
import TableOption from "../../Components/Writer/Contact/TableOption";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setStaffId } from "../../store";
export default ()=> {
    let searchQuery=useRef("");
    let dispatch=useDispatch();
    let page=useRef(1);
    let limit=useRef(20);
    let maxPageRef=useRef(1);
    const [data,setData]=useState([]);
    const [showModalAdd,setShowModalAdd]=useState(false)
    const [showModalUpdate,setShowModalUpdate]=useState(false);
    const [loading,setLoading]=useState(true);
    const modalRef={
        add:{
            icon:useRef(null),
            link:useRef(null)
        },
        update:{
            id:useRef(""),
            icon:useRef(null),
            link:useRef(null),
        }
    }
    const getAllData=async()=>{
        setLoading(true);
        const req=await readAll(limit.current,page.current,searchQuery.current)
        maxPageRef.current=req.meta.pagination.maxPage;
        setData(req.data);        
        setLoading(false);
    }
    useEffect(()=>{
        getAllData();
        
    },[])
    return (
      <WriterLayout>
        <ModalAdd show={showModalAdd} setShow={setShowModalAdd} onSubmit={async()=>{await getAllData();setShowModalAdd(false)}} Ref={modalRef.add}/>
        <ModalUpdate show={showModalUpdate} setShow={setShowModalUpdate} onSubmit={async()=>{await getAllData();setShowModalUpdate(false)}} Ref={modalRef.update}/>
        <Row className="mt-4 container">
            <Button variant="primary" onClick={()=>setShowModalAdd(n=>!n)}>
                <FontAwesomeIcon icon={faPlus}/>
                <span>Add Data</span>
            </Button>
        </Row>
        <Row>
            {
                loading
                ?
                <div className="d-flex justify-content-center">
                    <Oval width={30} height={30}/>
                </div>
                :
                <>
                    <DataTables 
                        onPageChange={async (e)=>{page.current=e;await getAllData()}} 
                        searchQuery={searchQuery.current}
                        currentPage={page.current} 
                        maxPage={maxPageRef.current} 
                        data={data}
                        onLimitChange={async (e)=>{
                            limit.current=e;
                            await getAllData()
                        }}
                        onSearch={async (e)=>{   
                            searchQuery.current=e                         
                            await getAllData()
                        }}
                        limitSelected={limit.current}
                        limitList={[20,40,60,80,100]}
                        tableHead={<tr>
                                <th>Icon</th>
                                <th>Link</th>
                                <th>Control</th>
                                </tr>}
                        tableBody={(item,index)=>(
                            <tr key={index}>
                                <td><FontAwesomeIcon icon={brandsIcon[item.icon]}/></td>
                                <td>{item.link}</td>
                                <td>
                                    <TableOption
                                     item={item}
                                     updateDataRef={modalRef.update}
                                     showModalState={{
                                        update_data:setShowModalUpdate
                                     }}
                                     onDeleted={async()=>{
                                        page.current=1;
                                        await getAllData();
                                     }}
                                    />
                                </td>
                            </tr>
                        )}
                    />
                </>
            }
        
        </Row>
      </WriterLayout>
    );
  }

