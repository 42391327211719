import Dashboard from "../Pages/Writer/Dashboard";
import EBook from "../Pages/Writer/EBook";
import Article from "../Pages/Writer/Article";
import PreviewArticle from "../Pages/Writer/PreviewArticle";
import Personal from "../Pages/Writer/Personal";
import Contact from "../Pages/Writer/Contact";
import AllNotification from "../Pages/Shared/AllNotification";
const NotFound=()=>{
  return (
    <div className="w-100 justify-content-center d-flex align-items-center" style={{height:'100vh'}}>
      <div className="border border-1 rounded-3 justify-content-center d-flex align-items-center p-3">
        Page Not Found
      </div>
    </div>
  )
}

const route = [
  {
    'path': '/',
    'element': <Dashboard />
  },
  {
    'path': '/notification',
    'element': <AllNotification />
  },
  {
    'path': 'personal',
    'element': <Personal />
  },
  {
    'path': 'article',
    'element': <Article />
  },
  {
    'path': 'read/:id',
    'element': <PreviewArticle />
  },
  {
    'path': 'ebook',
    'element': <EBook />
  },
  {
    'path': 'contact',
    'element': <Contact />
  },
  {
    'path': '*',
    'element': <NotFound />
  }
  
];
export default route;