import { useState,useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCommenting,faTrash } from "@fortawesome/free-solid-svg-icons";
import { deleteComment } from "../../../Services/Member/Comment";
import Swal from 'sweetalert2'
import ReplyBox from "./ReplyBox";
import ReplyTextBox from "./ReplyTextBox";
import { useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
export default ({data,readComment})=>{
    const [showReply,setShowReply]=useState(false);
    const nav=useNavigate
    const userData=useSelector(s=>s.session.userData)
    const replyRef=useRef(null);
    const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.onmouseenter = Swal.stopTimer;
          toast.onmouseleave = Swal.resumeTimer;
        }
    });
    const confirmDelete=async()=>{
        Swal.fire({
            title: 'Confirmation',
            text: `Delete This Comment?`,
            icon:'question',
            showCancelButton: true,
            confirmButtonText: "Yes",
            cancelButtonText: "Cancel",
        }).then(async (e) => {
            if(e.isConfirmed){
                const req=await deleteComment(data.id);
                Toast.fire({
                    icon: req.code==200?"success":"danger",
                    title: req.message
                });  
                await readComment();
            }
        })
        
    }
    return (
        <>
            <div className="m-3 p-2 border-1 border-bottom">
                <div className="fw-bold">{data.user.fullname}</div>
                <small className="text-muted">{data._time}</small>
                <div className="mt-3">{data.comment}</div>
                <div className="d-flex fs-7">
                    <small className="cursor-pointer" onClick={()=>{setShowReply(n=>!n)}}>
                        <FontAwesomeIcon icon={faCommenting}/>
                        <span className="ms-1">Reply ({data.replies.length})</span>
                    </small>
                    {
                        data.allow_delete
                        &&
                        <small className="cursor-pointer text-danger ms-3" onClick={()=>{confirmDelete()}}>
                            <FontAwesomeIcon icon={faTrash}/>
                            <span className="ms-1">Delete</span>
                        </small>
                    }
                </div>
            </div>
            {
                showReply
                &&
                <>
                    {
                        data.replies.map((item,index)=>(
                            <ReplyBox readComment={readComment} replyRef={replyRef} data={item} key={index}/>
                        ))
                    }
                    {
                        userData.type!="P"
                        ?
                        <ReplyTextBox replyRef={replyRef} id={data.id} onSubmit={async()=>{replyRef.current.value="";await readComment()}}/>
                        :
                        <div className="ms-4 mb-4">
                            <Button onClick={()=>nav("/login")} variant="primary">Login</Button> to give reply
                        </div>
                    }
                </>
            }
            
        </>
    )
}