import {useState } from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCaretDown, faCaretRight} from "@fortawesome/free-solid-svg-icons";

export default ({label,icon,showMenu,children})=>{
    const [showSubMenu,setShowSubMenu]=useState(false)
    return (
        <div className={`m-2 border border-1 rounded-3`}>
            <div onClick={()=>{setShowSubMenu(n=>!n)}} className={`p-3 cursor-pointer d-flex align-items-center ${!showMenu?"justify-content-center":"justify-content-between"}`}>
                <div className="d-flex">
                    <div><FontAwesomeIcon icon={icon}/></div>
                    <div className={`${showMenu?"d-block ms-2":"d-none"}`}>{label}</div>
                </div>
                <div><FontAwesomeIcon icon={showSubMenu?faCaretDown:faCaretRight}/></div>
            </div>
            {showSubMenu && <div className="ms-2">{children}</div>}
        </div>
    )
}