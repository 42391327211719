import { Modal,Button } from "react-bootstrap"
import { useEffect, useState } from "react"
import { readNotification } from "../../Services/Notification";
import { setNotification } from "../../store";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
export default ({show,setShow})=>{
    const [data,setData]=useState([]);
    const dispatch=useDispatch();
    const nav=useNavigate()
    const getNotification=async()=>{
        const req=await readNotification();
        dispatch(setNotification(0))
        setData(req.data)
    }
    useEffect(()=>{
        if(show){
            getNotification();
        }
    },[show])
    return (
    <Modal size="xl" show={show} onHide={()=>{setShow(false)}}>
        <Modal.Header closeButton>
        <Modal.Title>New Notification</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {
                data.length>0
                ?
                <>
                {
                    data.map((item,index)=>(
                    <a key={index} href={item.quick_link} target="_blank" className="d-block w-100 border-bottom border-1 mb-3">
                        <div>{item.message}</div>
                        <small className="text-secondary">{item._time}</small>
                    </a>
                    ))
                }
                </>
                :
                <div>no new notification</div>
            }
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={()=>{nav("/notification")}}>
                Look All Notification
            </Button>
        </Modal.Footer>
    </Modal>)
}