import { useState } from "react"
import { Oval } from "react-loader-spinner"
import { Button } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBook, faPencil,faTrash } from "@fortawesome/free-solid-svg-icons"
import { readOne,deleteData,publish,unpublish } from "../../../Services/Admin/Article";
import Swal from 'sweetalert2'
export default ({item,updateRef,showModalState,onDeleted})=>{
    const [loading,setLoading]=useState(false);
    const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.onmouseenter = Swal.stopTimer;
          toast.onmouseleave = Swal.resumeTimer;
        }
      });
    const getOneData=async()=>{
        setLoading(true);
        const req=await readOne(item.id);
        if(req.code==200){            
            updateRef.category.current={
                value:req.data.category_id,
                label:req.data.category.name
            }
            showModalState.update({
                show:true,
                articleType:req.data.type
            })
            setTimeout(()=>{                                
                updateRef.id.current=req.data.id;
                updateRef.title.current.value=req.data.title
                updateRef.short_description.current.value=req.data.short_description
                updateRef.type.current=req.data.type                
                if(req.data.type=="V"){
                    updateRef.link.current.value=req.data.content.link
                }
                else{
                    updateRef.content.current.setData(req.data.content.content)               
                }
                updateRef.ImgRef.current.src=req.data._img?req.data._img:""
                console.log(updateRef.category)
            },1000)
        }
        setLoading(false)
    }
    const confirmDelete=async()=>{
        Swal.fire({
            title: 'Confirmation',
            text: `Delete This Article : ${item.title}?`,
            icon:'question',
            showCancelButton: true,
            confirmButtonText: "Yes",
            cancelButtonText: "Cancel",
        }).then(async (e) => {
            if(e.isConfirmed){
                setLoading(true)
                const req=await deleteData(item.id);
                Toast.fire({
                    icon: req.code==200?"success":"danger",
                    title: req.message
                });  
                setLoading(false)
                await onDeleted();
            }
        })
        
    }
    const confirmPublish=async()=>{
        Swal.fire({
            title: 'Confirmation',
            text: `Publish This Article : ${item.title}?`,
            icon:'question',
            showCancelButton: true,
            confirmButtonText: "Yes",
            cancelButtonText: "Cancel",
        }).then(async (e) => {
            if(e.isConfirmed){
                setLoading(true)
                const req=await publish(item.id);
                Toast.fire({
                    icon: req.code==200?"success":"danger",
                    title: req.message
                });  
                setLoading(false)
                await onDeleted();
            }
        })
        
    }
    const confirmUnpublish=async()=>{
        Swal.fire({
            title: 'Confirmation',
            text: `Unublish This Article : ${item.title}? note : the visitor cannot read your article until you publish it again`,
            icon:'question',
            showCancelButton: true,
            confirmButtonText: "Yes",
            cancelButtonText: "Cancel",
        }).then(async (e) => {
            if(e.isConfirmed){
                setLoading(true)
                const req=await unpublish(item.id);
                Toast.fire({
                    icon: req.code==200?"success":"danger",
                    title: req.message
                });  
                setLoading(false)
                await onDeleted();
            }
        })
        
    }
    return (
        <>
        {
            loading?
            <div className="d-flex w-100 justify-content-center">
                <Oval width={30} height={30}/>
            </div>
            :
            <div className="row">
                <Button className="m-2" onClick={async()=>{getOneData()}} variant="secondary">
                    <FontAwesomeIcon icon={faPencil}/>
                    <span className="ms-2">Edit Data</span>
                </Button>
                <a href={process.env.REACT_APP_BASE_URL+"read/"+(item.title.replace(" ","-"))} target="_blank">
                    <Button className="m-2" variant="success">
                        <FontAwesomeIcon icon={faBook}/>
                        <span className="ms-2">Preview Article</span>
                    </Button>
                </a>
                {
                    item.publish=="0"
                    ?
                    <Button onClick={()=>{confirmPublish()}} className="m-2" variant="success">
                        <FontAwesomeIcon icon={faBook}/>
                        <span className="ms-2">Publish Article</span>
                    </Button>
                    :
                    <Button onClick={()=>{confirmUnpublish()}} className="m-2" variant="warning">
                        <FontAwesomeIcon icon={faBook}/>
                        <span className="ms-2">Unpublish</span>
                    </Button>
                }
                <Button className="m-2" variant="danger" onClick={async()=>{confirmDelete()}}>
                    <FontAwesomeIcon icon={faTrash}/>
                    <span className="ms-2">Delete Data</span>
                </Button>
            </div>
        }
        </>
    )
}