import { get,post,put,del } from "../Fetch"
const readAll=async (limit,page,query)=>{
    const req=await get("writer/ebook?limit="+limit+"&page="+page+"&q="+query);
    return req
}
const readOne=async (id)=>{
    const req=await get("writer/ebook/"+id);
    return req
}

const addData=async (formRef)=>{
    const fr=new FormData();
    fr.append("name",formRef.name.current.value);
    fr.append("link",formRef.link.current.value);
    fr.append("description",formRef.description.current.value);
    fr.append("img",formRef.img.current.files[0]);
    const req=await post("writer/ebook",fr);
    return req
}
const updateData=async (formRef)=>{
    const fr=new FormData();
    fr.append("name",formRef.name.current.value);
    fr.append("link",formRef.link.current.value);
    fr.append("description",formRef.description.current.value);
    fr.append("img",formRef.img.current.files[0]);
    const req=await put("writer/ebook/"+formRef.id.current,fr);
    return req
}

const deleteData=async (id)=>{
    const req=await del("writer/ebook/"+id);
    return req
}

export {readAll,addData,updateData,readOne,deleteData}