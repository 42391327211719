import { useState } from "react";
import { Button } from "react-bootstrap";
import TextArea from "../../Shared/TextArea"
import { sendReply } from "../../../Services/Member/Comment";
import { Oval } from "react-loader-spinner";
import Swal from 'sweetalert2'
import { useSelector } from "react-redux";
export default ({id,replyRef,onSubmit})=>{
    const [loading,setLoading]=useState(false);
    const [errorMessage,setErrorMessage]=useState({
        reply:""
    })
    const sock=useSelector(s=>s.session.sock);
    const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.onmouseenter = Swal.stopTimer;
          toast.onmouseleave = Swal.resumeTimer;
        }
    });
    const submitData=async()=>{
        setLoading(true);
        const req=await sendReply(replyRef,id);        
        if(req.code!=200){
            if(Array.isArray(req.message)){
                setErrorMessage(req.message.reduce((acc,current)=>{return {...acc,...current};},{}))
            }
            else{
                setErrorMessage({
                    reply:"",
                })
            }
        }
        else{
            Toast.fire({
                icon: "success",
                title: req.message
            });              
        }
        sock.emit('sending_comment') 
        await onSubmit();
        setLoading(false);
        
    }
    return (<div className="ms-5">
        <TextArea errorMessage={errorMessage.reply} Ref={replyRef} label="Your Reply " placeholder="Use civilized and good word only"/>
        <div>
        {
            loading
            ?
            <Oval width={30} height={30}/>
            :
            <Button onClick={async()=>{await submitData();}} variant="primary" >Send</Button>
        }
        </div>
    </div>)
}