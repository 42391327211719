import { useEffect,useState } from "react"
import { readAll } from "../../Services/Admin/Dashboard"
import { Oval } from "react-loader-spinner"
import AdminLayout from "../../Layout/Index"
import { Row } from "react-bootstrap"
import Box from "../../Components/Admin/Dashboard/Box"
import { faBook, faFilter, faSheetPlastic, faUserTie, faVideo } from "@fortawesome/free-solid-svg-icons"
export default ()=>{
    const [loading,setLoading]=useState(true);
    const [data,setData]=useState({
        "staff":0,
        "article_video":0,
        "article_blog":0,
        "category":0,
        "user_writer":0,
        "category":0,
    });
    const getData=async()=>{
        setLoading(true);
        let req=await readAll();
        setData(req.data);
        setLoading(false);
    }
    useEffect(()=>{
        window.AOS.init({
            offset:0
        });
        getData();
        
    },[])
    return (
    <AdminLayout>
        <Row className="justify-content-around mt-4">
            {
                loading
                ?
                <Oval width={30} height={30}/>
                :
                <>
                    <Box label="Staff / Writer" to="staff" data={data.staff} icon={faUserTie}/>
                    <Box label="Article Category" to="category" data={data.category} icon={faFilter}/>
                    <Box label="Video" to="article" data={data.article_video} icon={faVideo}/>
                    <Box label="Blog" to="article" data={data.article_blog} icon={faSheetPlastic}/>
                    <Box label="E-Book" to="ebook" data={data.ebook} icon={faBook}/>
                </>
            }
        </Row>
    </AdminLayout>
    )
}