import { useEffect, useState } from "react";
import { logout } from "../Services/Auth";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars, faBook, faBell, faFilter, faGear, faH, faHome, faSheetPlastic, faUser, faUserAstronaut, faUserFriends, faUserTie} from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Col } from "react-bootstrap";
import Swal from 'sweetalert2'
import MenubarItem from "../Components/Shared/Layout/MenubarItem";
import MenubarDropdownItem from "../Components/Shared/Layout/MenubarDropdownItem";
import MenubarDropdown from "../Components/Shared/Layout/MenubarDropdown";
import ModalNotification from "../Components/Shared/ModalNotification";
export default({children})=>{
    const [stick,setStick]=useState(false);
    const userData=useSelector(s=>s.session.userData);
    const [showMenu,setShowMenu]=useState(false);
    const [showModal,setShowModal]=useState(false);
    const nav=useNavigate();
    const sock=useSelector(s=>s.session.sock)
    const notification=useSelector(s=>s.session.notification)
    const handleScroll=()=>{
        let scrollNow=window.scrollY;
        setStick(scrollNow>=120);
    }
    useEffect(()=>{
        window.addEventListener('scroll', handleScroll);
        return ()=>{
            window.removeEventListener('scroll',handleScroll)
        }
    },[])
    const logOut=async()=>{
        Swal.fire({
            title: 'Confirmation',
            text: 'Are you sure you want to logout ?',
            icon:'question',
            showCancelButton: true,
            confirmButtonText: "Logout",
            cancelButtonText: "Cancel",
        }).then(async (e) => {
            if(e.isConfirmed){
                sock.emit("logout")
                await logout();
                localStorage.setItem("token_multiteam_web","")
                setTimeout(()=>{
                    window.location.href=process.env.REACT_APP_BASE_URL;
                },1000)
            }
        })
        
    }
    return(
    <>
        <ModalNotification show={showModal} setShow={setShowModal}/>
        <div>
            <div className="d-block d-md-flex">
                <div className={`sidebar-menu bg-dark text-light col-12 ${showMenu ? "col-md-3" :"col-md-1"}`}>
                    <div className="d-flex justify-content-between align-items-center d-md-none">
                        <div className="p-3">
                            Multiteam Admin Area
                        </div>
                        <div className="d-flex align-items-center">
                            <div className="p-3 cursor-pointer" onClick={()=>{setShowMenu(n=>!n)}}> 
                                <FontAwesomeIcon icon={faBars} size="small"/>
                            </div>
                            <div onClick={async()=>{setShowModal(true)}} className="cursor-pointer d-lg-none d-flex align-items-center mx-3">
                                <FontAwesomeIcon icon={faBell} />
                                <div>{notification}</div>
                            </div>
                        </div>
                    </div>
                    <div className={`bg-secondary content ${showMenu ? "active":""}`}>
                        <div className={`m-2 p-3 border border-1 rounded-3`}>
                            <div className={`d-flex align-items-center ${!showMenu?"justify-content-center":""}`}>
                                <div><img src={userData._img} className="rounded-5" style={{width:showMenu?'50px':'30px',height:showMenu?'50px':'30px'}}/></div>
                                <div style={{overflow:'hidden',textOverflow:'ellipsis'}} className={`w-100 text-center ${showMenu?"d-block":"d-none"}`}>{userData.fullname}</div>
                            </div>
                            <div className={`mt-4 ${showMenu?"row justify-content-around":'d-none'}`}>
                                <Button onClick={()=>{nav('/personal')}} variant="primary" className="col-12 col-lg-5 m-1">Personal</Button>
                                <Button onClick={()=>{logOut()}} variant="danger" className="col-12 col-lg-5 m-1">Logout</Button>
                            </div>
                        </div>
                        <MenubarItem label="Home" to="/" icon={faHome} showMenu={showMenu}/>
                        
                        <MenubarDropdown label="Learning Center" icon={faBook} showMenu={showMenu}>
                            <MenubarDropdownItem to="/category" label="Category" icon={faFilter} showMenu={showMenu}/>
                            <MenubarDropdownItem to="/ebook" label="E-Book" icon={faBook} showMenu={showMenu}/>
                            <MenubarDropdownItem to="/article" label="Article" icon={faSheetPlastic} showMenu={showMenu}/>
                        </MenubarDropdown>
                        <MenubarDropdown label="User Management" icon={faUser} showMenu={showMenu}>
                            <MenubarDropdownItem to="/staff" label="Staff / Writter" icon={faUserTie} showMenu={showMenu}/>
                            <MenubarDropdownItem to="/member" label="Member" icon={faUserAstronaut} showMenu={showMenu}/>
                            <MenubarDropdownItem to="/visitor" label="visitor" icon={faUserFriends} showMenu={showMenu}/>
                        </MenubarDropdown>
                        <MenubarItem label="Application Configuration" to="/config" icon={faGear} showMenu={showMenu}/>
                    </div>
                    
                </div>
                <Col className="w-100" style={{height:'100vh',overflowY:'auto'}}>
                    <div className="d-none bg-dark text-light d-md-flex justify-content-between align-items-center w-100">
                        <div className="p-3 cursor-pointer" onClick={()=>{setShowMenu(n=>!n)}}> 
                            <FontAwesomeIcon icon={faBars} size="small"/>
                        </div>
                        
                        <div className="d-flex justify-content-around align-items-center me-3">
                        <div className="p-3">
                            Multiteam Admin Area
                        </div>
                            <div onClick={async()=>{setShowModal(true)}} className="cursor-pointer d-flex align-items-center">
                                <FontAwesomeIcon icon={faBell} />
                                <div>{notification}</div>
                            </div>
                        </div>
                    </div>
                    <div>
                        {children}
                    </div>
                </Col>
            </div>
        </div>
    </>
    )
}