import { get,post,put,del } from "../Fetch"
const readAll=async (limit,page,query)=>{
    const req=await get("admin/article?limit="+limit+"&page="+page+"&q="+query);
    return req
}
const readOne=async (id)=>{
    const req=await get("admin/article/"+id);
    return req
}

const prevOne=async (id)=>{
    const req=await get("admin/article/prev/"+id);
    return req
}

const publish=async (id)=>{
    const req=await get("admin/article/publish/"+id);
    return req
}
const unpublish=async (id)=>{
    const req=await get("admin/article/unpublish/"+id);
    return req
}

const addData=async (formRef)=>{
    const fr=new FormData();
    fr.append("title",formRef.title.current.value);
    fr.append("type",formRef.type.current.value);
    fr.append("short_description",formRef.short_description.current.value);
    fr.append("category",formRef.category.current.value);
    if(formRef.type.current.value=="V"){
        fr.append("link",formRef.link.current.value);
    }
    else{
        fr.append("content",formRef.content.current.getData());
    }
    fr.append("img",formRef.img.current.files[0]);
    const req=await post("admin/article",fr);
    return req
}

const updateData=async (formRef)=>{
    const fr=new FormData();
    fr.append("title",formRef.title.current.value);
    fr.append("type",formRef.type.current.value);
    fr.append("short_description",formRef.short_description.current.value);
    fr.append("category",formRef.category.current.value);
    if(formRef.type.current.value=="V"){
        fr.append("link",formRef.link.current.value);
    }
    else{
        fr.append("content",formRef.content.current.getData());
    }
    fr.append("img",formRef.img.current.files[0]);
    const req=await put("admin/article/"+formRef.id.current,fr);
    return req
}

const deleteImage=async (img)=>{
    img=img.split("/")
    if(img.length>0){
        img=img[img.length-1].split(".")[0];
    }
    const req=await del("admin/article/delete-image/"+img);
    console.log(req)
    return req
}

const deleteData=async (id)=>{
    const req=await del("admin/article/"+id);
    return req
}

export {readAll,deleteImage,prevOne,addData,updateData,readOne,deleteData,publish,unpublish}