import { useEffect, useState } from "react";
import Footer from "../Components/Shared/Footer";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars} from "@fortawesome/free-solid-svg-icons";
import { useNavigate} from "react-router-dom";
import {useSelector} from "react-redux"
import MenubarItem from "../Components/Shared/Layout/MenubarItem";
export default({children})=>{
    const [stick,setStick]=useState(false);
    const [showMenu,setShowMenu]=useState(false);
    const token=useSelector(state=>state.session.token);
    const nav=useNavigate();
    const handleScroll=()=>{
        let scrollNow=window.scrollY;
        setStick(scrollNow>=120);
    }
    useEffect(()=>{
        window.addEventListener('scroll', handleScroll);
        return ()=>{
            window.removeEventListener('scroll',handleScroll)
        }
    },[])
    const GoTo=(path)=>{
        nav(path);
        setShowMenu(false);
    }
    return(
    <div>
      <div className="main">
        <div style={{top:'0px',zIndex:'3',boxShadow:'0px 1px 10px 1px black'}} 
        className={`${stick?'position-fixed w-100 bg-white text-black':'position-relative bg-dark text-light'} d-block d-lg-flex justify-content-lg-between`}>
            <div className="d-flex justify-content-lg-center justify-content-between p-2 align-items-center">
                <div>Multiteam</div>
                <div className="d-block d-lg-none cursor-pointer" onClick={()=>setShowMenu(n=>!n)}>
                    <FontAwesomeIcon size="2x" icon={faBars}/>
                </div>
            </div>
            <div className={showMenu?'d-block active menu-item d-lg-flex justify-content-between':'d-block menu-item d-lg-flex justify-content-between'}>
                <MenubarItem setShowMenu={setShowMenu} label="Home" to='/'/>
                <MenubarItem setShowMenu={setShowMenu} label="Our Staff" to='/staff'/>
                <MenubarItem setShowMenu={setShowMenu} label="E-book" to='/ebook'/>
                <MenubarItem setShowMenu={setShowMenu} label="Learning Center" to='/learning-center'/>
                <MenubarItem setShowMenu={setShowMenu} label="Join Us" to='/'/>
                <MenubarItem setShowMenu={setShowMenu} label="Login" to='/Login'/>
            </div>
        </div>
        {children}
      </div>
      <Footer/>
    </div>
    )
}