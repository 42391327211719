import TextInput from "../../Shared/TextInput"
import Select from "../../Shared/Select"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons"
import { Col } from "react-bootstrap"
export default({searchRef,page,getEBook})=>{
    return (
        <div className="d-flex mt-4 justify-content-center">
            <Col xs={10} md={6} lg={4}>
            <TextInput 
            Ref={searchRef}
            onKeyUp={async()=>{
                page.current=1;
                await getEBook()
            }}
            icon={<FontAwesomeIcon size="1x" icon={faMagnifyingGlass}/>} placeholder="search" name="search"/>
            </Col>
        </div>
    )
}