import { useState } from "react"
import { Oval } from "react-loader-spinner"
import { Button } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLock, faPencil,faPhone,faTrash, faUser } from "@fortawesome/free-solid-svg-icons"
import { useNavigate } from "react-router-dom"
import { readOne,deleteData } from "../../../Services/Admin/Staff";
import Swal from 'sweetalert2'
export default ({item,updateDataRef,updatePasswordRef,showModalState,onDeleted})=>{
    const nav=useNavigate();
    
    const [loading,setLoading]=useState(false);
    const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.onmouseenter = Swal.stopTimer;
          toast.onmouseleave = Swal.resumeTimer;
        }
      });
    const getOneData=async()=>{
        setLoading(true);
        const req=await readOne(item.id);
        if(req.code==200){
            showModalState.update_data(true)
            setTimeout(()=>{
                console.log(updateDataRef)
                updateDataRef.id.current=req.data.id;
                updateDataRef.username.current.value=req.data.user.username
                updateDataRef.fullname.current.value=req.data.user.fullname
                updateDataRef.bio.current.value=req.data.bio
                updateDataRef.short_bio.current.value=req.data.short_bio                
                updateDataRef.ImgRef.current.src=req.data._img?req.data._img:""
            },100)
        }
        setLoading(false)
    }
    const confirmDelete=async()=>{
        Swal.fire({
            title: 'Confirmation',
            text: `Delete This User : ${item.user.fullname}?`,
            icon:'question',
            showCancelButton: true,
            confirmButtonText: "Yes",
            cancelButtonText: "Cancel",
        }).then(async (e) => {
            if(e.isConfirmed){
                setLoading(true)
                const req=await deleteData(item.id);
                Toast.fire({
                    icon: req.code==200?"success":"danger",
                    title: req.message
                });  
                setLoading(false)
                await onDeleted();
            }
        })
        
    }
    return (
        <>
        {
            loading?
            <div className="d-flex w-100 justify-content-center">
                <Oval width={30} height={30}/>
            </div>
            :
            <div className="row">
                <Button className="m-2" onClick={async()=>{getOneData()}} variant="secondary">
                    <FontAwesomeIcon icon={faPencil}/>
                    <FontAwesomeIcon icon={faUser}/>
                    <span className="ms-2">Edit Data</span>
                </Button>
                <Button className="m-2" onClick={async()=>{updatePasswordRef.id.current=item.id;showModalState.update_password(true)}} variant="secondary">
                    <FontAwesomeIcon icon={faPencil}/>
                    <FontAwesomeIcon icon={faLock}/>
                    <span className="ms-2">Edit Password</span>
                </Button>
                <Button className="m-2" onClick={()=>{nav("/contact/"+item.id)}} variant="success">
                    <FontAwesomeIcon icon={faPhone}/>
                    <span className="ms-2">Staff's Contact</span>
                </Button>
                <Button className="m-2" variant="danger" onClick={async()=>{confirmDelete()}}>
                    <FontAwesomeIcon icon={faTrash}/>
                    <span className="ms-2">Delete Data</span>
                </Button>
            </div>
        }
        </>
    )
}