import { useEffect,useState } from "react";
import { useParams} from "react-router-dom";
import { prevOne } from "../../Services/Admin/Article";
import { Oval } from "react-loader-spinner";
import parser from "html-react-parser"
import AdminLayout from "../../Layout/Index";
import { Card } from "react-bootstrap";
import CommentBox from "../../Components/Public/Article/CommentBox";
import CommentTextBox from "../../Components/Public/Article/CommentTextBox";
import { readComment } from "../../Services/Articles";
import 'ckeditor5/ckeditor5.css';
export default ()=>{
    const {id}=useParams();
    const [data,setData]=useState([]);
    const [comment,setComment]=useState([]);
    const [loading,setLoading]=useState(true);
    const read=async()=>{
        setLoading(true);
        const req=await prevOne(id);   
        console.log(id);     
        if(req){
            setData(req.data);
            await getComment(req.data.id)
            document.title=req.data.title
        }
        
        setLoading(false);
    }
    const getComment=async(id)=>{
        const comment=await readComment(id);
        if(comment.code==200){
            setComment(comment.data);
        }
    }
    useEffect(()=>{
        window.scrollTo(0,0);
        read();
    },[id])
    return (
    <AdminLayout>
        <div className="w-100 p-3">
            {
                loading
                ?
                <div className="d-flex justify-content-center">
                    <Oval width={30} height={30}/>
                </div>
                :
                <Card>
                    <Card.Header>
                        <h1>{data.title}</h1>
                        <div>{data._date_created}</div>
                        <div>{data.user.fullname}</div>
                    </Card.Header>
                    <Card.Body className="ck-content">
                        {data.type=="V"
                        &&
                        <iframe style={{width:'100%',height:'70vh'}} src={data.content.link} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        }
                        {data.type=="B"
                        &&
                        <>
                            {data._img && <div className="d-flex justify-content-center mb-4"> <div className="col-12 col-md-5"><img src={data._img} className="w-100"/></div></div>}
                            {parser(data.content.content)}
                        </>
                        }
                        {
                                comment.map((item,index)=>(
                                    <CommentBox readComment={async()=>{await getComment(data.id)}} key={index}  data={item} />
                                ))
                            }
                        <CommentTextBox id={data.id} onSubmit={async()=>{await getComment(data.id);}}/>
                    </Card.Body>
                </Card>
            }
        </div>
    </AdminLayout>
    )
}