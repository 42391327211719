import { Modal,Button } from "react-bootstrap"
import TextInput from "../../Shared/TextInput"
import TextArea from "../../Shared/TextArea"
import ReactSelect from "../../Shared/ReactSelect"
import { category } from "../../../Services/Select2"
import CKEditor from "../../Shared/CKEditor/CKEditor"
import { useState } from "react"
import { faArrowsUpDown, faBook, faGlobe, faSheetPlastic,  } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { addData } from "../../../Services/Admin/Article"
import { Oval } from "react-loader-spinner"
import Swal from 'sweetalert2'
import ImageUpload from "../../Shared/ImageUpload"
import Select from "../../Shared/Select"
export default ({Ref,show,setShow,onSubmit})=>{
    const [articleType,setArticleType]=useState("V");
    const [errorMessage,setErrorMessage]=useState({
        title:"",
        short_description:"",
        category:"",
        link:"",
        content:"",
        title:"",
    });
    const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.onmouseenter = Swal.stopTimer;
          toast.onmouseleave = Swal.resumeTimer;
        }
      });      
    const [loading,setLoading]=useState(false);
    const submitData=async()=>{
        console.log(Ref.category);
        setLoading(true);
        const req=await addData(Ref);        
        if(req.code!=200){
            if(Array.isArray(req.message)){
                setErrorMessage(req.message.reduce((acc,current)=>{return {...acc,...current};},{}))
            }
            else{
                setErrorMessage({
                    title:"",
                    category:"",
                    short_description:"",
                    link:"",
                    content:"",
                    title:"",
                    other:req.message
                })
            }
        }
        else{
            Toast.fire({
                icon: "success",
                title: req.message
            });           
            await onSubmit();    
        }
        setLoading(false);
        
    }
    return (
    <Modal size="xl" show={show} onHide={()=>{setShow(false)}}>
        <Modal.Header closeButton>
        <Modal.Title>Add Article</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <TextInput errorMessage={errorMessage.title} Ref={Ref.title} label="Article Name" placeholder="Article Name" icon={<FontAwesomeIcon icon={faSheetPlastic} size='1x'/>}/>
            <TextArea errorMessage={errorMessage.short_description} Ref={Ref.short_description} label="Short Description" placeholder=" Short Description"/>
            <Select onChange={(e)=>{setArticleType(e.target.value)}} Ref={Ref.type} icon={<FontAwesomeIcon icon={faArrowsUpDown} size='1x'/>} label="Type">
                <option value="V">Video</option>
                <option value="B">Blog</option>
            </Select>
            <ReactSelect errorMessage={errorMessage.category} optionsMap={(item)=>{return {value:item.id,label:item.name}}} loadOptions={category} Ref={Ref.category} label="Category"></ReactSelect>
            <>
                {articleType=="V"
                ?
                <TextInput errorMessage={errorMessage.link} Ref={Ref.link} label="Link" placeholder="Link" icon={<FontAwesomeIcon icon={faGlobe} size='1x'/>}/>
                :
                <CKEditor Ref={Ref.content} label="Content"/>
                }
            </>
            <ImageUpload Ref={Ref.img} label="Picture"/>
            {
                errorMessage.other!=""
                &&
                <div className="w-100 text-center text-danger">
                    {errorMessage.other}
                </div>
            }
        </Modal.Body>
        <Modal.Footer>
            {
                loading?
                <Oval width={30} height={30}/>
                :
                <>
                    <Button variant="secondary" onClick={()=>{setShow(false)}}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={async()=>{await submitData()}}>
                        Save Changes
                    </Button>
                </>
            }
        </Modal.Footer>
    </Modal>)
}