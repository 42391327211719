import { get } from "./Fetch";

const readOne=async(id)=>{
    const req=await get("article/"+id);
    return req;
}
const readComment=async(id)=>{
    const req=await get("article/read-comment/"+id);
    return req
}
export {readOne,readComment}