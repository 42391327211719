import { Modal,Button } from "react-bootstrap"
import TextInput from "../../Shared/TextInput"
import { useEffect, useState } from "react"
import { faLock, faUser,faUserCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { updatePassword } from "../../../Services/Admin/Personal"
import { Oval } from "react-loader-spinner"
import Swal from 'sweetalert2'
export default ({Ref,show,setShow,onSubmit})=>{
    const [errorMessage,setErrorMessage]=useState({
        old:"",
        password:"",
    });
    const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.onmouseenter = Swal.stopTimer;
          toast.onmouseleave = Swal.resumeTimer;
        }
      });      
    const [loading,setLoading]=useState(false);
    const submitData=async()=>{
        setLoading(true);
        const req=await updatePassword(Ref);        
        if(req.code!=200){
            if(Array.isArray(req.message)){
                setErrorMessage(req.message.reduce((acc,current)=>{return {...acc,...current};},{}))
            }
            else{
                setErrorMessage({
                    old:"",
                    password:"",
                    confirm:"",
                    other:req.message
                })
            }
        }
        else{
            Toast.fire({
                icon: "success",
                title: req.message
            });        
            await onSubmit();
        }
        setLoading(false);
        
    }
    return (
    <Modal size="md" show={show} onHide={()=>{setShow(false)}}>
        <Modal.Header closeButton>
        <Modal.Title>Edit Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <TextInput type="password" errorMessage={errorMessage.old} Ref={Ref.old} label="Old Password" placeholder="Old Password" icon={<FontAwesomeIcon icon={faLock} size='1x'/>}/>
            <TextInput type="password" errorMessage={errorMessage.password} Ref={Ref.password} label="New Password" placeholder="New Password" icon={<FontAwesomeIcon icon={faLock} size='1x'/>}/>
            <TextInput type="password" errorMessage={errorMessage.confirm} Ref={Ref.confirm} label="Confirm New Password" placeholder="Confirm New Password" icon={<FontAwesomeIcon icon={faLock} size='1x'/>}/>
            
            {
                errorMessage.other!=""
                &&
                <div className="w-100 text-center text-danger">
                    {errorMessage.other}
                </div>
            }
        </Modal.Body>
        <Modal.Footer>
            {
                loading?
                <Oval width={30} height={30}/>
                :
                <>
                    <Button variant="secondary" onClick={()=>{setShow(false)}}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={async()=>{await submitData()}}>
                        Save Changes
                    </Button>
                </>
            }
        </Modal.Footer>
    </Modal>)
}