import { Modal,Button } from "react-bootstrap"
import TextInput from "../../Shared/TextInput"
import { useEffect, useState } from "react"
import { faLock, faUser, faUserAstronaut, faUserTie } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { updateData } from "../../../Services/Admin/Staff"
import { Oval } from "react-loader-spinner"
import Swal from 'sweetalert2'
import TextArea from "../../Shared/TextArea"
import ImageUpload from "../../Shared/ImageUpload"
export default ({Ref,show,setShow,onSubmit})=>{
    const [errorMessage,setErrorMessage]=useState({
        username:"",
        fullname:"",
        password:"",
        confirm:"",
        bio:"",
        short_bio:"",
    });
    const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.onmouseenter = Swal.stopTimer;
          toast.onmouseleave = Swal.resumeTimer;
        }
      });      
    const [loading,setLoading]=useState(false);
    const submitData=async()=>{
        setLoading(true);
        const req=await updateData(Ref);        
        if(req.code!=200){
            if(Array.isArray(req.message)){
                setErrorMessage(req.message.reduce((acc,current)=>{return {...acc,...current};},{}))
            }
            else{
                setErrorMessage({
                    username:"",
                    fullname:"",
                    bio:"",
                    short_bio:"",
                    'other':req.message
                })
            }
        }
        else{
            Toast.fire({
                icon: "success",
                title: req.message
            });        
            await onSubmit();
        }
        setLoading(false);
        
    }
    return (
    <Modal size="md" show={show} onHide={()=>{setShow(false)}}>
        <Modal.Header closeButton>
        <Modal.Title>Edit Staff</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <TextInput errorMessage={errorMessage.username} Ref={Ref.username} label="Username" placeholder="Username" icon={<FontAwesomeIcon icon={faUser} size='1x'/>}/>
            <TextInput errorMessage={errorMessage.fullname} Ref={Ref.fullname} label="Fullname" placeholder="Fullname" icon={<FontAwesomeIcon icon={faUserTie} size='1x'/>}/>
            <TextInput errorMessage={errorMessage.short_bio} Ref={Ref.short_bio} label="Short Bio" placeholder="Short Bio" icon={<FontAwesomeIcon icon={faUserAstronaut} size='1x'/>}/>
            <TextArea errorMessage={errorMessage.bio} Ref={Ref.bio} label="Bio" placeholder="Bio"/>
            <ImageUpload Ref={Ref.img} ImgRef={Ref.ImgRef} label="Picture"/>
            {
                errorMessage.other!=""
                &&
                <div className="w-100 text-center text-danger">
                    {errorMessage.other}
                </div>
            }
        </Modal.Body>
        <Modal.Footer>
            {
                loading?
                <Oval width={30} height={30}/>
                :
                <>
                    <Button variant="secondary" onClick={()=>{setShow(false)}}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={async()=>{await submitData()}}>
                        Save Changes
                    </Button>
                </>
            }
        </Modal.Footer>
    </Modal>)
}