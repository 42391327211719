import { get,post,put,del } from "../Fetch"
const readAll=async (limit,page,query)=>{
    const req=await get("admin/category?limit="+limit+"&page="+page+"&q="+query);
    return req
}
const readOne=async (id)=>{
    const req=await get("admin/category/"+id);
    return req
}
const select2=async (query)=>{
    const req=await get("admin/category/select2?q="+query);
    return req
}

const addData=async (formRef)=>{
    const fr=new FormData();
    fr.append("name",formRef.name.current.value);
    const req=await post("admin/category",fr);
    return req
}
const updateData=async (formRef)=>{
    const fr=new FormData();
    fr.append("name",formRef.name.current.value);
    const req=await put("admin/category/"+formRef.id.current,fr);
    return req
}
const deleteData=async (id)=>{
    const req=await del("admin/category/"+id);
    return req
}

export {readAll,addData,updateData,readOne,select2,deleteData}