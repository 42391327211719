import { get,post } from "./Fetch";

const login=async(formRef)=>{
    const fr=new FormData();
    fr.append("username",formRef.username.current.value);
    fr.append("password",formRef.password.current.value);
    fr.append("captcha",formRef.captcha.current);
    const req=await post("login",fr);
    return req;
}
const logout=async()=>{
    const req=await get("logout");
    return req;
}

const checkUser=async(token)=>{
    const req=await get("check-user",token);
    return req;
}
export {login,checkUser,logout}