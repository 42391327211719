import Dashboard from "../Pages/Public/Dashboard"
import Article from "../Pages/Public/Article"
import Editor from "../Pages/Public/Editor";
import LearningCenter from "../Pages/Public/LearningCenter";
import EBook from "../Pages/Public/EBook";
import Staff from "../Pages/Public/Staff";
import DataTables from "../Pages/Public/DataTables";
import Login from "../Pages/Public/Login";
import StaffDetail from "../Pages/Public/StaffDetail";
import GuessLogin from "../Pages/Public/GuessLogin";
import AllNotification from "../Pages/Shared/AllNotification";
const route = [
  {
    'path': '/',
    'element': <Dashboard />
  },
  {
    'path': '/notification',
    'element': <AllNotification />
  },
  {
    'path': '/admin-login',
    'element': <Login />
  },
  {
    'path': '/login',
    'element': <GuessLogin />
  },
  {
    'path': '/learning-center',
    'element': <LearningCenter />
  },
  {
    'path': '/data-table',
    'element': <DataTables />
  },
  {
    'path': '/ebook',
    'element': <EBook />
  },
  {
    'path': '/staff-detail/:id',
    'element': <StaffDetail />
  },
  {
    'path': '/read/:id',
    'element': <Article />
  },
  {
    'path': '/editor',
    'element': <Editor />
  },
  {
    'path': '/staff',
    'element': <Staff/>
  }
];
export default route;