import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Card,Button} from "react-bootstrap";
import { useNavigate} from "react-router-dom";
export default ({label,icon,to,data})=>{
    const nav=useNavigate()
    return (<Card className="col-10 col-md-5 col-lg-3 m-2">
        <Card.Header className="text-center">
            {label}
        </Card.Header>
        <Card.Body className="d-flex justify-content-between">
            <h1>
                <FontAwesomeIcon icon={icon}/>
            </h1>
            <h1>
                {data}
            </h1>
        </Card.Body>
        <Card.Footer className="d-flex justify-content-center">
            <Button onClick={()=>{nav(to)}} variant="success">See More</Button>
        </Card.Footer>
    </Card>)
}