import {Row,Col, Table} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpDown,faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons"
import TextInput from './TextInput';
import Select from './Select';
import { useEffect, useRef, useState } from 'react';
export default ({tableBody,data,tableHead,currentPage,maxPage,onLimitChange,onSearch,limitSelected,searchQuery,limitList,onPageChange})=> {
    const [currentPageState,setCurrentPage]=useState(currentPage);
    const [maxPageState,setMaxPage]=useState(maxPage);
   const [pageElement,setPageElement]=useState([]);
   const setPage=()=>{        
    let max=currentPageState+2;
    let min=currentPageState-2;
    
    if(min<=0){
        min=1;
    }
    if(currentPageState<=3){
        max=5;
    }
    if(max>maxPageState){
        max=maxPageState;
    }
    setPageElement((n)=>{
        let newElement=[];
        for(let i=min;i<=max;i++){
            newElement.push(i);
        }
        return newElement;
    })
}
   useEffect(()=>{
    setPage()
   },[])
  return (
    <div className="p-3">
        <Row className='justify-content-center justify-content-md-between'>
            <Col xs={10} md={5} className='mb-4'>
            <TextInput
                value={searchQuery}
                onKeyUp={async (e)=>{
                    if(e.code=="Enter"){
                        await onSearch(e.target.value);
                    }
                }}
            icon={<FontAwesomeIcon size="1x" icon={faMagnifyingGlass}/>} placeholder="search" name="search"/>   
            </Col>
            <Col xs={10} md={5}>
            <Select 
            onChange={(e)=>{onLimitChange(e.target.value)}} icon={<FontAwesomeIcon size="1x" icon={faUpDown}/>}>
                {limitList.map((item)=>(
                    <option selected={item==limitSelected} value={item}>{item}</option>
                ))}
            </Select>
            </Col>
        </Row>
        <Table striped>
          <thead>
            {
                tableHead
            }
          </thead>
          <tbody>
            {
                data.map(tableBody)
            }
          </tbody>
        </Table>
        <div className="d-flex justify-content-end mt-4">
            <div onClick={async()=>{setCurrentPage(1);await onPageChange(1);}} className="p-1 border border-1 cursor-pointer">
                {"|<"}
            </div>
            <div onClick={async()=>{setCurrentPage(async (n)=>{
                n=n-1<=0?1:n-1;
                await onPageChange(n);
                return n});}} className="p-1 border border-1 cursor-pointer">
                {"<<"}
            </div>
            {
                pageElement.map((item,index)=>(
                    <div onClick={async()=>{setCurrentPage(item);await onPageChange(item)}} className={`p-1 border border-1 cursor-pointer ${item==currentPageState?"bg-success":""}`}>
                        {item}
                    </div>
                ))
            }
            <div onClick={async()=>{setCurrentPage(async (n)=>{
                n=n+1>maxPageState?maxPageState:n+1
                await onPageChange(n);
                return n;
                })}} className="p-1 border border-1 cursor-pointer">
                {">>"}
            </div>
            <div onClick={async()=>{setCurrentPage(maxPageState);await onPageChange(maxPageState);}} className="p-1 border border-1 cursor-pointer">
                {">|"}
            </div>
        </div>
    </div>
  );
}