import { useEffect,useState,useRef } from "react";
import { useNavigate, useParams} from "react-router-dom";
import { readAllNotification } from "../../Services/Notification";
import { Oval } from "react-loader-spinner";

import PublicLayout from "../../Layout/Index";
import { Button, Card } from "react-bootstrap";
import {Helmet} from "react-helmet"

import { useSelector } from "react-redux";
export default ()=>{
    const {id}=useParams();
    const [loading,setLoading]=useState(true);
    const [data,setData]=useState([]);
    const read=async()=>{
        setLoading(true);
        const req=await readAllNotification();        
        if(req.code==200){
            setData(req.data);
        }
        
        setLoading(false);
    }
    useEffect(()=>{
        window.scrollTo(0,0);
        read();
    },[id])
    return (
    <>
        <Helmet>
            <title>Notification</title>
        </Helmet>
        <PublicLayout>
        <div className="w-100 p-3">
            {
                loading
                ?
                <div className="d-flex justify-content-center">
                    <Oval width={30} height={30}/>
                </div>
                :
                <>
                {
                    data.map((item,index)=>(
                    <a key={index} href={item.quick_link} target="_blank" className="d-block w-100 border-bottom border-1 mb-3">
                        <div>{item.message}</div>
                        <small className="text-secondary">{item._time}</small>
                    </a>
                    ))
                }
                </>
            }
        </div>
    </PublicLayout>
    </>
    )
}