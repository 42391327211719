import { useNavigate } from "react-router-dom";
import { Col, Row,Button} from 'react-bootstrap';
export default ({newEBook,randomEBook})=>{
    const nav=useNavigate();
    return (
    <div className='row mt-4' >
        <Col xs={12} className='text-center'>
          <div className="fs-1">EBook</div>
            <div>
                <Button variant="success" onClick={()=>{nav('ebook')}}>
                    See All
                </Button>
            </div>
        </Col>
        <div className="d-flex justify-content-center">
        <Col xs={10} className='col-10 mt-4'>
                <Row className="justify-content-around">
                    <Col xs={12} lg={5} data-aos="fade-bottom" style={{overflow:'hidden'}}>
                        {newEBook.length>0
                        ?
                        <>
                        <div className="position-absolute bg-primary text-center " style={{
                            zIndex: '0',
                            top:'0',
                            left: '-25px',
                            width: '100px',
                            transform: 'rotateZ(-45deg)',
                            top: '10px'}}>
                            New
                        </div> 
                        
                        <Row >
                            <Col xs={12} md={4}>
                                <img src={newEBook[0]._img} className={newEBook[0].img?"rounded-5":"rounded-5 bg-dark"} style={{width:'100%',height:'100%'}}/>
                            </Col>
                            <Col xs={12} md={8} className="p-3">
                                <Row className="mx-3">
                                    <div className="fs-3 text-center">{newEBook[0].name}</div>
                                    <div className="fs-6">Author : {newEBook[0].user.fullname}</div>
                                    <p className="text-secondary">date :  {newEBook[0]._date_created}</p>
                                    <div className="w-100">
                                        <Row className="justify-content-around">
                                            <a href={newEBook[0].link}>
                                                <Button variant="primary" className="mb-4 mb-md-0">
                                                    Read
                                                </Button>
                                            </a>
                                        </Row>
                                    </div>
                                </Row>
                            </Col>
                        </Row>
                        </>
                        :
                        <div>ebook is empty :)</div>
                        }
                    </Col>
                    {
                        randomEBook.length>0
                        &&
                        <Col xs={12} lg={5}  className="mt-4 mt-lg-0" data-aos="fade-bottom">
                        <div className="text-center">
                            <h3>Others that you may like</h3>
                        </div>
                    {
                        randomEBook.map((item,index)=>(
                        <Row key={index}>
                            <Col xs={12} className="border border-1 border-solid rounded-5 mb-4">

                                <div className="row">
                                    <Col xs={12} md={4}>
                                        <img src={item._img} className={item.img?"rounded-5":"rounded-5 bg-dark"} style={{width:'100%',height:'100%'}}/>
                                    </Col>
                                    <Col xs={12} md={8} className="p-3">
                                        <Row className="mx-3">
                                            <div className="fs-3 text-center">{item.name}</div>
                                            <div className="fs-6">Author : {item.user.fullname}</div>
                                            <p className="text-secondary">date : {item._date_created}</p>
                                            <div className="w-100">
                                                <Row className="justify-content-around">
                                                <a href={newEBook[0].link}>
                                                    <Button as="input" variant="primary" className="mb-4 mb-md-0 col-10 col-md-4" value="Read"/>
                                                </a>
                                                </Row>
                                            </div>
                                        </Row>
                                    </Col>
                                </div>
                                
                            </Col>
                        </Row>
                        ))
                    }
                    </Col>
                    }

                </Row>
            </Col>
        </div>
      </div>
    )
}