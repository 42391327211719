import { useEffect, useState } from "react";

export default({icon,Ref,ImgRef,errorMessage,label,name})=>{
    let className="w-100 d-flex border border-1 rounded-3 align-items-center custom-input p-1 m-1";
    const [prev,setPrev]=useState();
    if(errorMessage){
        className+=" border-danger";
    }
    
    return (
        <div className="mb-3">
            {label&& <label className="ms-3">{label}</label>}
            <div className={className}>
            {icon}
            <div className="w-100">
            <img className="d-block" src={prev} ref={ImgRef} style={{width:'200px',height:'200px'}}/>
            <input className="d-block" name={name} type="file" ref={Ref} onChange={(e)=>{setPrev(URL.createObjectURL(e.target.files[0]))}}/>                
            </div>
            
        </div>
        {errorMessage!=""&&
            <div className="text-danger mt-3">
                {errorMessage}
            </div>
            }
        </div>
    )
}