import { Modal,Button } from "react-bootstrap"
import TextInput from "../../Shared/TextInput"
import Select from "../../Shared/Select"
import { useEffect, useState } from "react"
import { faImage,faPhone } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { update } from "../../../Services/Writer/Contact"
import { Oval } from "react-loader-spinner"
import Swal from 'sweetalert2'
export default ({Ref,show,setShow,onSubmit})=>{
    const [errorMessage,setErrorMessage]=useState({
        icon:"",
        link:""
    });
    const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.onmouseenter = Swal.stopTimer;
          toast.onmouseleave = Swal.resumeTimer;
        }
      });      
    const [loading,setLoading]=useState(false);
    const submitData=async()=>{
        setLoading(true);
        const req=await update(Ref);        
        if(req.code!=200){
            if(Array.isArray(req.message)){
                setErrorMessage(req.message.reduce((acc,current)=>{return {...acc,...current};},{}))
            }
            else{
                setErrorMessage({
                    icon:"",
                    link:"",
                    'other':req.message
                })
            }
        }
        else{
            Toast.fire({
                icon: "success",
                title: req.message
            });        
            await onSubmit();
        }
        setLoading(false);
        
    }
    return (
    <Modal size="md" show={show} onHide={()=>{setShow(false)}}>
        <Modal.Header closeButton>
        <Modal.Title>Edit Contact</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Select errorMessage={errorMessage.icon} Ref={Ref.icon} label="Icon" placeholder="Icon" icon={<FontAwesomeIcon icon={faImage} size='1x'/>}>
                <option value="faGithub">github</option>
                <option value="faInstagram">instagram</option>
                <option value="faYoutube">youtube</option>
                <option value="faFacebook">facebook</option>
                <option value="faLinkedin">linkedin</option>
            </Select>
            <TextInput errorMessage={errorMessage.link} Ref={Ref.link} label="Link" placeholder="Link" icon={<FontAwesomeIcon icon={faPhone} size='1x'/>}/>
        </Modal.Body>
        <Modal.Footer>
            {
                loading?
                <Oval width={30} height={30}/>
                :
                <>
                    <Button variant="secondary" onClick={()=>{setShow(false)}}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={async()=>{await submitData()}}>
                        Save Changes
                    </Button>
                </>
            }
        </Modal.Footer>
    </Modal>)
}