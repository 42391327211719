import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCommenting ,faTrash} from "@fortawesome/free-solid-svg-icons";
import { deleteReply } from "../../../Services/Member/Comment";
import Swal from 'sweetalert2'
export default ({data,replyRef,readComment})=>{
    const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.onmouseenter = Swal.stopTimer;
          toast.onmouseleave = Swal.resumeTimer;
        }
    });
    const confirmDelete=async()=>{
        Swal.fire({
            title: 'Confirmation',
            text: `Delete This Reply?`,
            icon:'question',
            showCancelButton: true,
            confirmButtonText: "Yes",
            cancelButtonText: "Cancel",
        }).then(async (e) => {
            if(e.isConfirmed){
                const req=await deleteReply(data.id);
                Toast.fire({
                    icon: req.code==200?"success":"danger",
                    title: req.message
                });  
                await readComment();
            }
        })
        
    }
    return (
        <div className="m-3 p-2 border-1 border-bottom ms-5">
            <div className="fw-bold">{data.user.fullname}</div>
            <small className="text-muted">{data._time}</small>
            <div className="mt-3">{data.reply}</div>
            <div className="d-flex fs-7">
                <small className="cursor-pointer" onClick={()=>{replyRef.current.value="@"+data.user.fullname+" "}}>
                    <FontAwesomeIcon icon={faCommenting}/>
                    <span className="ms-1">Reply</span>
                </small>
                {
                        data.allow_delete
                        &&
                        <small className="cursor-pointer text-danger ms-3" onClick={()=>{confirmDelete()}}>
                            <FontAwesomeIcon icon={faTrash}/>
                            <span className="ms-1">Delete</span>
                        </small>
                    }
            </div>
        </div>
    )
}