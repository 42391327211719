
import parser from 'html-react-parser'
import { Col, Row} from 'react-bootstrap';
export default ({data})=>{
    return (
    <Row className='p-4 align-items-center ' data-aos="fade-left">
        <Col xs={12}>
          <div className='fs-1 w-100 text-center'>About Us</div>
          <div className='w-100 '>{parser(data[0].about_us)}</div>
        </Col>
    </Row>
    )
}