import TextInput from "../../Shared/TextInput"
import Select from "../../Shared/Select"
import { Col,Row } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons"
import { faUpDown } from "@fortawesome/free-solid-svg-icons"
import { useEffect, useState } from "react"
import { readAllCategory } from "../../../Services/LearningCenter"
export default({category,searchRef,page,getArticle})=>{
    const [catList,setCatList]=useState([]);
    const readCategory=async()=>{
        const req=await readAllCategory();
        
        if(req.data){
            setCatList(req.data);
        }
    }
    useEffect(()=>{
        readCategory()
    },[])
    return (
        <Row className="mt-4 justify-content-center">
            <Col xs={10} md={5} lg={4}>
            <TextInput 
            Ref={searchRef}
            onKeyUp={async()=>{
                page.current=1;
                await getArticle()
            }}
            icon={<FontAwesomeIcon size="1x" icon={faMagnifyingGlass}/>} placeholder="search" name="search"/>
            </Col>
            <Col xs={10} md={5} lg={4} className="ms-0 ms-md-4">
            <Select onChange={async(e)=>{
                category.current=e.target.value;
                page.current=1
                await getArticle();
            }} icon={<FontAwesomeIcon size="1x" icon={faUpDown}/>}>
                <option value={"-"}>All Category</option>
                {catList.map((item)=>(
                    <option value={item.id}>{item.name}</option>
                ))}
            </Select>
            </Col>
            
        </Row>
    )
}