import { useEffect,useState,useRef } from "react";
import { useNavigate, useParams} from "react-router-dom";
import { readOne,readComment } from "../../Services/Articles";
import { Oval } from "react-loader-spinner";
import parser from "html-react-parser"
import PublicLayout from "../../Layout/Index";
import { Button, Card } from "react-bootstrap";
import {Helmet} from "react-helmet"

import CommentBox from "../../Components/Public/Article/CommentBox";
import CommentTextBox from "../../Components/Public/Article/CommentTextBox";
import { useSelector } from "react-redux";
export default ()=>{
    const {id}=useParams();
    const [data,setData]=useState([]);
    const userData=useSelector(s=>s.session.userData)
    const [comment,setComment]=useState([]);
    const [loading,setLoading]=useState(true);
    const nav=useNavigate()
    const read=async()=>{
        setLoading(true);
        const req=await readOne(id);        
        if(req.code==200){
            setData(req.data);
            await getComment(req.data.id);
        }
        
        setLoading(false);
    }
    const getComment=async(id)=>{
        const comment=await readComment(id);
        if(comment.code==200){
            setComment(comment.data);
        }
    }
    useEffect(()=>{
        window.scrollTo(0,0);
        read();
    },[id])
    return (
    <>
        <Helmet>
            <title>{data.title}</title>
            <meta property="og:title" content={data.title} />
            <meta property="og:description" content={data.short_description?data.short_description:data.title} />
            <meta property="og:image" content={data._img?data._img:process.env.REACT_APP_API_URL+"img-config/banner.jpg"} />
            <meta property="og:url" content={process.env.REACT_APP_BASE_URL+"read/"+id} />
            <meta property="og:type" content="website" />
            <meta property="title" content={data.title} />
            <meta property="description" content={data.short_description?data.short_description:data.title} />
            <meta property="og:image" content={data._img?data._img:process.env.REACT_APP_API_URL+"img-config/banner.jpg"} />
            <meta property="url" content={process.env.REACT_APP_BASE_URL+"read/"+id} />
            <meta property="type" content="website" />
        </Helmet>
        <PublicLayout>
        <div className="w-100 p-3">
            {
                loading
                ?
                <div className="d-flex justify-content-center">
                    <Oval width={30} height={30}/>
                </div>
                :
                <Card>
                    <Card.Header>
                        <h1>{data.title}</h1>
                        <div>{data._date_created}</div>
                        <div>{data.user.fullname}</div>
                    </Card.Header>
                    <Card.Body className="ck-content">
                        {data.type=="V"
                        &&
                        <iframe style={{width:'100%',height:'70vh'}} src={data.content.link} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        }
                        {data.type=="B"
                        &&
                        <>
                            {data._img && <div className="d-flex justify-content-center mb-4"> <div className="col-12 col-md-5"><img src={data._img} className="w-100"/></div></div>}
                            {parser(data.content.content)}
                        </>
                        }
                        <div className="mt-3">
                            <div className="fs-5">{
                            comment.length>0?
                            "Comment"
                            :
                            "Be First To Comment"
                            }</div>
                            {
                                comment.map((item,index)=>(
                                    <CommentBox readComment={async()=>{await getComment(data.id)}} key={index}  data={item} />
                                ))
                            }
                            
                            {
                                userData.type!="P"
                                ?
                                <CommentTextBox id={data.id} onSubmit={async()=>{await getComment(data.id);}}/>
                                :
                                <div>
                                    <Button onClick={()=>nav("/login")} variant="primary">Login</Button> to give comment
                                </div>
                            }
                        </div>
                    </Card.Body>
                </Card>
            }
        </div>
    </PublicLayout>
    </>
    )
}