import { useState,useRef } from "react";
import { Oval } from "react-loader-spinner";
import { Button, Card } from "react-bootstrap";
import TextArea from "../../Shared/TextArea"
import { sendComment } from "../../../Services/Member/Comment";
import { useSelector } from "react-redux";
import Swal from 'sweetalert2'
export default ({id,onSubmit})=>{
    const [loading,setLoading]=useState(false);
    const [errorMessage,setErrorMessage]=useState({
        comment:""
    })
    const sock=useSelector(s=>s.session.sock);
    const commentRef=useRef(null);
    
    const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.onmouseenter = Swal.stopTimer;
          toast.onmouseleave = Swal.resumeTimer;
        }
    });
    const submitData=async()=>{
        setLoading(true);
        const req=await sendComment(commentRef,id);        
        if(req.code!=200){
            if(Array.isArray(req.message)){
                setErrorMessage(req.message.reduce((acc,current)=>{return {...acc,...current};},{}))
            }
            else{
                
                setErrorMessage({
                    comment:"",
                })
            }
        }
        else{
            Toast.fire({
                icon: "success",
                title: req.message
            });      
            sock.emit('sending_comment')        
        }
        await onSubmit();
        commentRef.current.value=""
        setLoading(false);
        
    }
    return (
        <>
        <TextArea errorMessage={errorMessage.comment} Ref={commentRef} label="Your Comment " placeholder="Use civilized and good word only"/>
        {
            loading
            ?
            <Oval width={30} height={30}/>
            :
            <Button onClick={async()=>{await submitData();}} variant="primary" >Send</Button>
        }
        </>
    )
}