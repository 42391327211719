import { get,post,put,del } from "../Fetch"

const sendComment=async (formRef,id)=>{
    const fr=new FormData();
    fr.append("comment",formRef.current.value);
    const req=await post("member/comment/send-comment/"+id,fr);
    return req
}
const deleteComment=async (id)=>{
    const req=await del("member/comment/delete-comment/"+id);
    return req
}
const deleteReply=async (id)=>{
    const req=await del("member/comment/delete-reply/"+id);
    return req
}
const sendReply=async (formRef,id)=>{
    const fr=new FormData();
    fr.append("reply",formRef.current.value);
    const req=await post("member/comment/send-reply/"+id,fr);
    return req
}

export {sendComment,sendReply,deleteComment,deleteReply}