import {Row,Col, Button, Card} from 'react-bootstrap';
import TextInput from '../../Components/Shared/TextInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser,faLock } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha";
import { useRef, useState } from 'react';
import { login } from '../../Services/Auth';
import { Oval } from "react-loader-spinner"
export default ()=>{
    const formRef={
        'username':useRef(null),
        'password':useRef(null),
        'captcha':useRef(null)
    }
    const [errorMessage,setErrorMessage]=useState({
        'username':"",
        'password':""
    })
    const  [loading,setLoading]=useState(false);
    const captcha_sitekey = process.env.REACT_APP_GOOGLE_CAPTCHA_SITEKEY;
    const captcha=useRef(null);
    const handleCaptcha = (e) => {
        formRef.captcha.current=e;
    }
    const nav=useNavigate();
    const doLogin=async()=>{
        setLoading(true);
        const req=await login(formRef);        
        if(req.code!=200){
            if(Array.isArray(req.message)){
                setErrorMessage(req.message.reduce((acc,current)=>{return {...acc,...current};},{}))
            }
            else{
                setErrorMessage({
                    'username':"",
                    'password':""
                })
            }
            captcha.current.reset();
        }
        else{
            localStorage.setItem('token_multiteam_web',req.data.token);
            setTimeout(()=>{
                window.location.href=process.env.REACT_APP_BASE_URL;
            },500)
            
        }
        setLoading(false);
    }
    return (
        <div style={{height:'100vh'}} className='d-flex justify-content-center align-items-center'>
            <Col xs={10} md={7} lg={4}>
                <Card>
                    <Card.Header>
                        <h3 className='text-center'>Admin Login</h3>
                    </Card.Header>
                    <Card.Body>
                        <TextInput errorMessage={errorMessage.username} Ref={formRef.username} label="Username" placeholder="username" icon={<FontAwesomeIcon icon={faUser} size='1x'/>}/>
                        <TextInput errorMessage={errorMessage.password} Ref={formRef.password} label="Password" placeholder="password" type="password" icon={<FontAwesomeIcon icon={faLock} size='1x'/>}/>
                        <div className='mt-4'>
                        <ReCAPTCHA
                            ref={captcha}
                            sitekey={captcha_sitekey}
                            onChange={handleCaptcha}
                            />
                        </div>
                    </Card.Body>
                    <Card.Footer>
                        <div className='d-flex w-100 justify-content-center'>
                            <Button onClick={()=>{
                                if(!loading){doLogin();}
                            }} variant='primary'>
                                {
                                    loading?
                                    <Oval width={25} height={25}/>
                                    :
                                    <span>Login</span>
                                }
                            </Button>
                            <Button onClick={()=>{window.history.go(-1)}} variant='secondary' className='ms-3'>
                                Kembali
                            </Button>
                        </div>
                    </Card.Footer>
                </Card>
            </Col>
        </div>
    )
}