import { get,post,put,del } from "../Fetch"
import { useSelector } from "react-redux";
const readAll=async (limit,page,query)=>{
    const req=await get("writer/contact/?limit="+limit+"&page="+page+"&q="+query);
    return req
}
const readOne=async (id)=>{
    const req=await get("writer/contact/"+id);
    return req
}

const addData=async (formRef)=>{
    const fr=new FormData();
    fr.append("icon",formRef.icon.current.value);
    fr.append("link",formRef.link.current.value);
    const req=await post("writer/contact",fr);
    return req
}
const update=async (formRef)=>{
    const fr=new FormData();
    fr.append("icon",formRef.icon.current.value);
    fr.append("link",formRef.link.current.value);
    const req=await put("writer/contact/"+formRef.id.current,fr);
    return req
}
const deleteData=async (id)=>{
    const req=await del("writer/contact/"+id);
    return req
}

export {readAll,addData,update,readOne,deleteData}