
import { Col, Row} from 'react-bootstrap';
export default ({data})=>{
    return (
    <Row className=' h-90-vh align-items-center bg-dark' data-aos="fade-top" style={{overflow:'hidden'}}>
        <Col xs={12} lg={8} className='text-center' style={{background:'rgb(95,95,95,0.5)'}}>
          <div className='fs-1 w-100 text-light'>Welcome To {data[0].name} </div>
          <div className='fs-5 w-100 text-light'>One Intention, One Determination </div>
        </Col>
        <Col xs={12} lg={4}>
            <div className='d-flex w-100 justify-content-center'>
                <img src={data[0]._logo} style={{width:'100%'}}/>
            </div>          
        </Col>
    </Row>
    )
}