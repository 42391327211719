export default({icon,errorMessage,label,name,type,value,placeholder,Ref,onChange,onKeyUp})=>{
    let className="w-100 d-flex border border-1 rounded-3 align-items-center custom-input p-1 m-1";
    if(errorMessage){
        className+=" border-danger";
    }
    return (
        <div className="mb-3">
            {label&& <label className="ms-3">{label}</label>}
            <div className={className}>
            {icon}
            <div className="d-flex position-relative ms-2 w-100">
                <input onChange={onChange} onKeyUp={onKeyUp} className="w-100" name={name} type={type} defaultValue={value} placeholder={placeholder} ref={Ref}/>                
            </div>
            
        </div>
        {errorMessage!=""&&
            <div className="text-danger mt-3">
                {errorMessage}
            </div>
            }
        </div>
    )
}