import { get } from "./Fetch";

const readAll=async(page,q=false)=>{
    const req=q?await get("staff?page="+page+"&q="+q):await get("staff?page="+page);
    return req;
}
const readOne=async(id)=>{
    const req=await get("staff/"+id);
    return req;
}
export {readAll,readOne}