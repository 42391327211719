import { Col, Row,Button} from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
export default ({newArticle,randomArticle})=>{
    const nav=useNavigate();
    return (
    <Row className='mt-4' >
        <Col xs={12} className='text-center'>
          <div className="fs-1">Multiteam Learning Center</div>
            <div>
            <Button variant="success" onClick={()=>{nav('learning-center')}}>
                    See All
                </Button>
            </div>
        </Col>
        <Col xs={12} className='mt-4'>
            <Row className="justify-content-around">                
                    {newArticle.length>0
                    ?
                    <Col xs={12} lg={5} data-aos="fade-bottom" style={{overflow:'hidden'}}>                                              
                        <Row className='justify-content-center'>
                            <Col xs={8} md={4}>
                                <img src={newArticle[0]._img} className={newArticle[0].img?"rounded-5":"rounded-5 bg-dark"} style={{width:'100%',height:'100%'}}/>
                            </Col>
                            <Col xs={12} md={8} className="p-3">
                                <Row className="mx-3">
                                    <div className="fs-3 text-center">{newArticle[0].title}</div>
                                    <div className="fs-6">Author : {newArticle[0].user.fullname}</div>
                                    <p className="text-secondary">date :  {newArticle[0]._date_created}</p>
                                    <div className="w-100">
                                        <Row className="justify-content-around">
                                            <Button as="input" variant="primary" onClick={()=>{nav('/read/'+newArticle[0]._slug)}} className="mb-4 mb-md-0 col-10 col-md-4" value="Read"/>
                                        </Row>
                                    </div>
                                </Row>
                            </Col>
                        </Row>
                        <div className="position-absolute bg-primary text-center " style={{
                            zIndex: '0',
                            top:'0',
                            left: '-25px',
                            width: '100px',
                            transform: 'rotateZ(-45deg)',
                            top: '10px'}}>
                            New
                        </div>  
                    </Col>
                    :
                    <div>no article right now :)</div>
                    }
                 
                {
                    randomArticle.length>0
                    &&
                    <Col xs={12} lg={5} className="mt-4 mt-lg-0" data-aos="fade-bottom">
                    <div className="text-center">
                        <h3>Others that you may like</h3>
                    </div>
                    {
                    randomArticle.map((item,index)=>(
                    <Row key={index}>
                        <Col xs={12} className="border border-1 border-solid rounded-5 mb-4">
                            <Row className='justify-content-center'>
                                <Col xs={8} md={4}>
                                    <img src={item._img} className={item.img?"rounded-5":"rounded-5 bg-dark"} style={{width:'100%',height:'100%'}}/>
                                </Col>
                                <Col xs={12} md={8} className="p-3">
                                    <Row className="mx-3">
                                        <div className="fs-3 text-center">{item.title}</div>
                                        <div className="fs-6">Author : {item.user.fullname}</div>
                                        <p className="text-secondary">date : {item._date_created}</p>
                                        <div className="w-100">
                                            <Row className="justify-content-around">
                                                <Button as="input" variant="primary" onClick={()=>{nav('/read/'+item._slug)}} className="mb-4 mb-md-0 col-10 col-md-4" value="Read"/>
                                            </Row>
                                        </div>
                                    </Row>
                                </Col>
                            </Row>
                            
                        </Col>
                    </Row>
                    ))
                     }
                    </Col>
                }

            </Row>
        </Col>
      </Row>
    )
}